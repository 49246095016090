/* start admin sidebar css */
.admin-sidebar-logo-main .sidebar-close-btn {
    display: none;
}

.admin-sidebar-icons {
    height: 18px;
    object-fit: contain;
}

.admin-sidebar-main.active {
    left: 0px;
}

.admin-sidebar-logo {
    height: 35px;
    width: 100%;
    object-fit: contain;
    margin: auto;
    display: flex;
    order: 2;
}


.close-icon {
    display: none;
}

.admin-sidebar-logo-main {
    padding: 30px 0px 30px;
    position: relative;
}

.admin-sidebar-list-main {
    padding: 16px 0px;
    overflow-x: auto;
    height: calc(100vh - 157px);
}

.submenu-sidebr-details li .down-uparrow-btn {
    position: absolute;
    right: 0;
}

.admin-header-drop-main .drop-header-btn {
    padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-list {
    padding: 0px 15px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
    padding: 0px;
    position: relative;
}

.admin-sidebar-list-main .admin-sidebar-link {
    font-size: 12px;
    line-height: 15px;
    font-family: 'Poppins-Regular';
    color: #FFFFFF;
    padding: 12px 12px 12px;
    width: 100%;
    text-decoration: none;
    display: flex;
    gap: 5px;
    align-items: center;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
    color: #FFFFFF;
    border-radius: 4px;
}

.admin-sidebar-main::-webkit-scrollbar {
    width: 2px;
}


.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-link {
    margin-left: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-listitem:first-child .admin-sidebar-link {
    margin-top: 20px;
}

.admin-sidebar-main::-webkit-scrollbar {
    width: 2px;
}

.admin-sidebar-main::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.admin-sidebar-main::-webkit-scrollbar-thumb {
    background: #888;
}

.admin-sidebar-main::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* end admin sidebar css */
/* start admin header css */


.admin-header-main {
    background-color: #FFFFFF;
    top: 0px;
    padding-left: 290px;
    right: 0px;
    z-index: 9;
    padding: 15px 20px 13px 20px;
    border-bottom: 1px solid #E5E7EB;
    transition: 0.5s all;
}

.admin-header-deactive {
    left: 0px;
}


.admin-header-logo-main .admin-bergur-button {
    padding: 0px;
    min-width: 30px;
}

.admin-header-main.admin-header-deactive .admin-sidebar-logo {
    opacity: 1;
    transition: 0.5s all;
    margin-left: 10px;
    height: 35px;
}

.admin-header-logo-main {
    display: flex;
}

.admin-header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.admin-header-right .admin-header-icon-box {
    padding: 0px;
    min-width: 30px;
    margin-right: 23px;
}


.admin-header-icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
}

.admin-header-profile-icon {
    height: 36px;
    width: 36px;
    object-fit: cover;
    border-radius: 5px;
}

/* end admin header css */





/* start header profile dropdown  */

.flex-drop-main .admin-header-drop {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    line-height: 18px;
    color: #6B7280;
    text-align: start;
    margin-left: 12px;
    text-transform: capitalize;
}

.flex-drop-main .header-drop-title {
    font-family: 'Poppins-Bold';
}

.flex-drop-main {
    display: flex;
    align-items: center;
}

.drop-header {
    height: 15px;
    width: 15px;
    margin-right: 6px;
}

.drop-header-menu .MuiPaper-rounded {
    box-shadow: 10px 10px 20px #0000001A;
    border: 1px solid #262339;
    margin-top: 13px;
    background: #262339;
    border-radius: 8px;
}

.drop-header {
    height: 15px;
    width: 15px;
    margin-right: 6px;
}

.drop-header-menu .drop-header-menuitem {
    font-size: 12px;
    line-height: 14px;
    color: #9fbac3;
    font-family: 'Poppins-Regular';
    border-bottom: 1px solid #203a44;
    padding: 11px 16px;
}

.drop-header-menu .drop-header-menuitem:last-child {
    border-bottom: none;
}

.drop-header-menu ul {
    padding: 0px;
}

/* end header profile dropdown  */


/* start auth footer social meadia */

.social-main .social-ul {
    padding: 0px;
    list-style-type: none;
    display: flex;
    margin: 20px 0px;
    justify-content: center;
}

.social-main .social-ul .social-li {
    padding: 0px;
    margin: 0px;
    width: max-content;
}

.social-box {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #D1D5DB;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons {
    height: 15px;
    width: 15px;
    object-fit: contain;
}

.social-main .social-ul .social-li:last-child .social-box {
    margin-right: 0px;
}

/* end auth footer social meadia */

/* Meta mask start*/

.meta-mask-btn-sec .meta-mask-btn,
.meta-mask-btn-sec .meta-mask-btn:hover {
    background: #102934;
    color: #FFF;
    border-radius: 20px;
    margin: 0 20px 0 0;
    font-size: 14px;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
    padding: 5px 15px;
}

.meta-mask-main .metamask-modal-inner-main {
    max-width: 364px;
    min-width: 364px;
    width: 100%;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 8px 15px #00000029;
    border: 1px solid #A1A1A1;
}

.metamask-header-main {
    padding: 15px 24px;
    background: #F3F3F3D1;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.metamask-header-main .metamask-head-logo {
    width: 17px;
    height: 35;
    object-fit: contain;
}

.downarrow-img {
    width: 16px;
    height: 16px;
    margin: 0 0 0 9px;
}

.user-detail-drop {
    position: relative;
}

.drop-user-img-sec .user-img {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-right: 7px;
}

.user-detail-drop .drop-user-img-sec,
.user-detail-drop .drop-user-img-sec:hover {
    display: flex;
    align-items: center;
    padding: 0;
}

.drop-user-img-sec .meta-drop-title {
    font-size: 13px;
    line-height: 15px;
    font-family: 'Poppins-Bold';
    color: #3B3B3B;
}

.cus-user-drop {
    position: absolute;
    background: #FFF;
    top: 35px;
    width: 100%;
    border-radius: 7px;
    box-shadow: 0px 5px 7px #00000029;
    /* display: none !important; */
}

.user-d-none {
    display: none !important;
}

.setting-d-non {
    display: none;
}

.cus-user-drop .main-list {
    padding: 10px;
    width: 100%;
}

.cus-user-drop .main-list .ul-listitem {
    padding: 0px;
    margin: 0 0 10px 0;
}

.cus-user-drop .main-list .ul-listitem:last-child {
    margin: 0 0 0px 0;
}

.three-dot-sec .three-dot-img {
    margin: 0 0 0 23px;
}

.three-dot-sec {
    display: flex;
    align-items: center;
}

.metamask-modal-inner-main .metamask-modal-body {
    /* padding: 30px 22px; */
    /* height: 700px; */
    padding: 14px 22px;
    overflow: auto;
    /* height: 486px; */
    height: 63.364vh;
}

.copy-text-sec-main .copy-text-sec {
    width: 141px;
    height: 41px;
    background: #112A355C;
    border-radius: 60px;
    /* padding: 12px 15px; */
    font-size: 14px;
    font-family: "Poppins-Medium";
    display: flex;
    align-items: center;
    justify-content: center;
    color: #112A35;
    cursor: pointer;
}

.metamask-modal-body .copy-text-sec-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.copy-text-sec .copy-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 7px;
}

.eth-text-sec {
    margin: 22px 0 26px 0;
    text-align: center
}

.eth-text-sec .eth-text {
    font-size: 36px;
    font-family: "Poppins-Medium";
    color: #000000;
    line-height: 43px;
}

.eth-text-sec .usd-text {
    font-size: 18px;
    font-family: "Poppins-Regular";
    color: #000000;
    line-height: 22px;
}

.buy-swap-btn .buy-btn,
.buy-swap-btn .buy-btn:hover {
    min-width: 45px;
    height: 45px;
    background: #6AD3AA;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.buy-swap-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.buy-swap-btn .buy-text {
    font-size: 15px;
    font-family: "Poppins-Regular";
    color: #3E3E3E;
    line-height: 18px;
    margin-top: 6px;
}

.buy-swap-sec {
    display: flex;
    margin-bottom: 30px;
}

.buy-swap-sec .swap-btn-sec {
    margin-left: 32px;
}

/* -------cus tab start------ */
.token-tab-sec .admin-tabs-main .admin-tab {
    font-size: 15px;
    line-height: 18px;
    font-family: 'Poppins-Regular';
    color: #111827;
    text-transform: capitalize;
    padding: 10px 15px;
    width: 50%;
}

.token-tab-sec .admin-tabs-main .admin-tab.Mui-selected {
    color: #112A35;
}

.token-tab-sec .admin-tabs-main .MuiTabs-indicator {
    background-color: #112A35;
    height: 2px;
}

.token-tab-sec .admin-tabpanel>.MuiBox-root {
    padding: 20px 0px;
}

.flexo-eth-usd {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.tabpanel-main .flexo-eth-usd:last-child {
    margin-bottom: 0px;
}

.flexo-img-sec {
    width: 38px;
    height: 38px;
    background: #112A35;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
}

.flexo-eth-main {
    display: flex;
    align-items: center;
}

.flexo-content-sec .flexo-text {
    font-size: 14px;
    line-height: 17px;
    font-family: 'Poppins-Regular';
    color: #000000;
}

.flexo-content-sec .eth-text {
    color: #929292;
}

.flexo-ic {
    width: 13px;
    height: 25px;
    object-fit: contain;
}

.activity-tab-main {
    min-height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activity-tab-main .no-trans-text {
    font-size: 13px;
    line-height: 15px;
    font-family: 'Poppins-Regular';
    color: #BFBFBF;
}

.three-dot-sec .dots-btn {
    min-width: 4px;
    padding: 0;
    margin: 0 0 0 20px;
}

.cus-dot-menu .drop-inner-img {
    margin: 0 10px 0 0;
}

.swap-header-sec {
    margin: 0 0 18px 0;
}

.cus-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.swap-header-title .swap-title {
    font-size: 20px;
    line-height: 24px;
    font-family: 'Poppins-Bold';
    color: #000000;
}

.cus-dot-drop {
    position: relative;
}

.cus-dot-drop .cus-user-drop {
    /* position: absolute; */
    right: -20px;
    width: 208px;
    top: 31px;
}

.cus-dot-drop .cus-user-drop .main-list {
    padding: 0;
}

.cus-dot-drop .cus-user-drop .main-list .ul-listitem {
    padding: 12px 14px 11px 14px;
    margin: 0;
    transition: all .5s ease;
}

.cus-dot-drop .cus-user-drop .main-list .ul-listitem:hover,
.cus-dot-drop .cus-user-drop .main-list .ul-listitem.active {
    background: #EFEFEF;
}

.left-errow-sec {
    display: flex;
}

.swap-value-wrapper {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 25px 22px;
    margin: 0px 0 100px 0;
    position: relative;
}

/* -------cus tab end------ */
/* -------cus dropdown start------ */
.dropdown-box .form-control {
    width: 100%;
    position: relative;
}

.swap-value-wrapper .dropdown-select .MuiSelect-select {
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Poppins-Medium';
    display: flex;
    padding: 7px 15px;
    border-radius: 50px;
    background: #DCDCDC;
}

.swap-value-wrapper .form-control fieldset {
    border: 1px solid #D1D5DB;
    border-radius: 50px;
}

.swap-value-wrapper .flexo-img-sec {
    width: 26px;
    height: 26px;
}

.swap-value-wrapper .flexo-img-sec .flexo-ic {
    width: 9px;
    height: 17px;
    object-fit: contain;
}

.swap-value-top .drop-eth,
.swap-value-bottom .drop-eth {
    width: 174px;
    margin-bottom: 4px;
}

/* -------cus dropdown end------ */
.swap-value-top .swap-value-right {
    font-size: 24px;
    line-height: 32px;
    font-family: 'Poppins-Bold';
    color: #000000;
}

.swap-value-top .swap-balance-text {
    font-size: 14px;
    line-height: 19px;
    font-family: 'Poppins-Regular';
    color: #000000;
}

.swap-value-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
}

.swap-value-bottom {
    padding-top: 37px;
}

.cus-line {
    height: 2px;
    background: #DCDCDC;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.cus-down-round {
    width: 48px;
    height: 48px;
    background: #DCDCDC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
    background: #112A35;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 19px;
    font-family: "Poppins-Medium";
    height: 52px;
    border-radius: 50px;
    text-transform: capitalize;
}

.terms-link {

    text-align: center;
    text-decoration: none;
    color: #112A35;
    font-size: 13px;
    line-height: 18px;
    font-family: "Poppins-Medium";
}

.terms-link-sec {
    text-align: center;
    margin-top: 13px;
}

/* -----------------input start-------- */
.input-wrapper {
    margin-bottom: 28px;
}

.input-wrapper .input-lable {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0px;
    font-family: 'Poppins-Regular';
    margin-bottom: 12px;
    margin-top: 0px;
    color: #7a779c;
}

.input-group .cus-input input {
    /* border: 1px solid #D1D5DB; */
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 12px 17px;
    background-color: #F5F5F5;
    color: #7a779c;
    font-size: 16px;
    line-height: 21px;
    font-family: 'Poppins-Regular';
    outline: none;
}

.input-group .cus-input input {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Regular';
    color: #7a779c;
    /* color: #C2C2C2; */
}

.cus-input fieldset {
    /* border: 1px solid #D1D5DB; */
    border: none;
    border-radius: 2px;
}

.search-wrapper .input-group .cus-input input {
    padding: 16.38px 15px 16.38px 45px;
    color: #7a779c;
    background-color: #F5F5F5;
}

.search-wrapper .input-group .cus-input input::placeholder {
    color: #7a779c;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Poppins-Regular';
}

.search-wrapper .input-group .cus-input input,
.search-wrapper .cus-input fieldset {
    border: none;
    border-radius: 5px;
}

.search-wrapper {
    position: relative;
    margin: 0 0 20px 0;
}

.search-wrapper .inp-search-ic {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
}

.setting-item-text .setting-item-title {
    font-size: 14px;
    line-height: 19px;
    font-family: 'Poppins-Regular';
    color: #000000;
    margin-left: 8px;
}

.setting-item {
    margin-bottom: 28px;
}

.d-flex-y {
    display: flex;
    align-items: center;
}

.network-wrapper .search-wrapper {
    margin: 0 0 17px 0;
}

.network-wrapper .mainnet-sec {
    padding: 0 9px 10px 9px;
}

.mainnet-sec .input-group .cus-input input {
    padding: 9.5px 15px 9.5px 45px;
}

.mainnet-sec .input-group .cus-input input::placeholder {
    font-size: 13px;
    line-height: 18px;
}

.mainnet-sec .inp-search-ic {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.mainnet-sec .flexo-content-sec {
    display: flex;
    align-items: center;
}

.mainnet-sec .flexo-eth-main {
    position: relative;
    padding-left: 23px;
    margin: 0 0 14px 0;
}

.mainnet-sec .flexo-eth-main::before {
    content: "";
    background-image: url('../../../assets/images/svg/check.svg');
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    display: none;
}

.mainnet-sec .flexo-eth-main.active::before {
    display: block;
}

.mainnet-sec .lock-ic {
    width: 16px;
    height: 16px;
    margin: 0 0 0 4px;
}

.mainnet-sec .flexo-img-sec {
    width: 26px;
    height: 26px;
}

.mainnet-sec .flexo-ic {
    width: 9px;
    height: 17px;
}

.wallet-sub-title .wallet-sub-text {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Regular';
    color: #000000;
}

.network-sec {
    padding-top: 16px !important;
}

.cus-scroll {
    height: 270px;
    overflow: auto;
    margin-right: -10px;
    padding-right: 6px;
}

/* width */
.cus-scroll::-webkit-scrollbar,
.metamask-modal-body::-webkit-scrollbar,
.input-group textarea::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.cus-scroll::-webkit-scrollbar-track .metamask-modal-body::-webkit-scrollbar-track,
.input-group textarea::-webkit-scrollbar-track .metamask-modal-body::-webkit-scrollbar-track {
    background: #ECECEC;
    border-radius: 5px;
}

/* Handle */
.cus-scroll::-webkit-scrollbar-thumb,
.metamask-modal-body::-webkit-scrollbar-thumb,
.input-group textarea::-webkit-scrollbar-thumb {
    background: #112A35;
    border-radius: 5px;
}

/* Handle on hover */
.cus-scroll::-webkit-scrollbar-thumb:hover,
.metamask-modal-body::-webkit-scrollbar-thumb:hover,
.input-group textarea::-webkit-scrollbar-thumb:hover {
    background: #112A35;
}

.metamask-footer {
    padding: 17.5px 20.5px;
    background: rgb(243 243 243 / 82%);
    border-radius: 0 0 5px 5px;
    display: flex;
}

.contactlist-main {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contactlist-main .contact-img {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.contactlist-main .contact-title {
    margin: 9px 0 4px 0;
    font-size: 16px;
    line-height: 21px;
    font-family: 'Poppins-Bold';
    color: #000000;
}

.contactlist-main .contact-sub-title {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Regular';
    color: #6A6A6A;
    margin-bottom: 28px;
}

.contactlist-main .add-contact-btn {
    min-width: 164px;
    height: 42px;
}

.scan-input {
    position: relative;
}

.scan-input .scanner-ic {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
}

.input-group.scan-input .cus-input input {
    padding: 12px 40px 12px 17px;
}

.metamask-footer .primary-btn,
.metamask-footer .primary-btn:hover {
    width: 150px;
    height: 44px;
}

.metamask-footer .cancel-btn,
.metamask-footer .cancel-btn:hover {
    margin-right: 21px;
    background: #B4B4B4;
}

.alert-detail {
    padding: 17px 9px;
    border-bottom: 2px solid #F5F5F5;
}

.alert-left {
    width: 188px;
    display: flex;
    align-items: center;
}

.alert-left .alert-text {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Regular';
    color: #6A6A6A;
}

.alert-left .information-img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin: 0 0 0 8px;
}

.alert-switch-sec .MuiFormControlLabel-root {
    margin-right: 0;
}

.alert-switch-sec .switch-main {
    margin-left: 0;
}

.input-wrapper .input-group textarea {
    width: 100%;
    height: 216px !important;
    background: #F5F5F5;
    border: none;
    border-radius: 5px;
    resize: none;
    padding: 13px 12px 11px 12px;
    outline: none;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Regular';
    color: #6A6A6A;
    overflow: auto !important;
}

.new-trans-input-sec {
    /* padding-bottom: 30px; */
    border-bottom: 2px solid #F5F5F5;
}

.transaction-info-sec .swap-header-title {
    text-align: center;
    margin: 15px 0;
}

.transaction-info-sec .dropdown-select .MuiSelect-select {
    background: #F5F5F5;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Regular';
    padding: 12px 15px;
}

.transaction-info-sec .form-control fieldset {
    border: none;
}

.add-transaction-btn-sec {
    display: flex;
    justify-content: end;
    margin-top: 32px;
}

.add-transaction-btn-sec .primary-btn,
.add-transaction-btn-sec .primary-btn:hover {
    width: 150px;
    height: 44px;
}

.mb-24 {
    margin-bottom: 24px;
}

.send-token-sec .send-token {
    font-size: 16px;
    line-height: 21px;
    font-family: 'Poppins-Bold';
    color: #000000;
}

.send-token-sec .nonce-text {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Regular';
    color: #000000;
    margin-right: 6px;
}

.send-token-sec {
    margin-bottom: 18px;
}

.nonce-sec {
    display: flex;
    align-items: center;
}

.nonce-sec .input-group .cus-input input {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Bold';
    color: #7a779c;
    background: transparent;
    border: 1px solid #A1A1A1;
    padding: 5px 7px 6px 7px;
    width: 48px;
}

.drop-menuitem .user-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 4px;
}

.MuiSelect-select .user-img {
    margin-right: 4px;
}

.metamask-modal-inner-main .dropdown-select .MuiSelect-select {

    background: #F5F5F5;
    border: none;
}

.metamask-modal-inner-main .form-control fieldset {
    border: none;
}

.scanner-drop {
    position: relative;
}

.scanner-drop .scanner-ic {
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 99;
    right: 38px;
    top: 50%;
    transform: translateY(-50%);
}

.input-box-row {
    display: flex;
}

.input-box-row .button-input {
    width: 165px;
    margin-right: 2px;
    position: relative;
}

.new-transaction-first .form-lable {
    font-size: 12px !important;
    line-height: 16px !important;
    font-family: 'Poppins-Regular';
    color: #6A6A6A;
    margin: 0 0 12px 0 !important;
}

.button-input .max-btn,
.button-input .max-btn:hover {
    min-width: 49px;
    height: 24px;
    background: #112A35;
    border-radius: 5px;
    color: #FFF;
    font-size: 10px;
    line-height: 13px;
    font-family: 'Poppins-Regular';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}

.button-input .input-group .cus-input input {
    border-radius: 5px 0 0 5px;
    padding: 12px 64px 12px 17px;
}

.scanner-drop .flexo-img-sec,
.MuiButtonBase-root .flexo-img-sec {
    width: 24px;
    height: 24px;
}

.scanner-drop .flexo-ic,
.MuiButtonBase-root .flexo-ic {
    width: 9px;
    height: 17px;
}

.drop-in-sec .goerli-ether {
    font-size: 10px;
    line-height: 13px;
    font-family: 'Poppins-Regular';
}

.drop-in-sec .gor-text {
    font-size: 8px;
    line-height: 13px;
    font-family: 'Poppins-Regular';
}

.scanner-drop .dropdown-select .MuiSelect-select {
    padding: 7.5px 15px;
    align-items: center;
}

/* .next-btn-sec .primary-btn{
   position: absolute;
   right: 21px;
   bottom: 21px;
} */

.right-main-position-modal {
    position: absolute;
    right: 60px;
    top: 60px;
}

.qr-inner-modal-wallet {
    position: absolute;
    top: 190px;
    right: 100px;
}

/* .metamask-modal-inner-main1 {
    width: 323px;
    height: 350px;
} */

.common-modal .user-qr-modal-inner {
    min-width: 270px;
}

.common-modal .common-modal-inner {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 8px 20px #00000042;
    overflow: auto;
    padding: 20px 15px;
}

.modal-close-icon {
    cursor: pointer;
    object-fit: contain;
    position: absolute;
    right: 23px;
    top: 31px;
    width: 12px;
    height: 12px;
}

.user-img {
    width: 35px;
    height: 35px;
    object-fit: cover;
}

.user-qr-img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7.5px;
}

.qr-code-img {
    height: 80px;
    width: 80px;
    object-fit: cover;
}

.qr-code-img-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-edit-icon {
    height: 13px;
    width: 13px;
    object-fit: contain;
    margin-left: 9px;
}

.user-qr-modal-content .user-qr-name {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 19px;
    font-family: 'Poppins-Bold';

}

.inner-modal-copy .copy-text-sec {
    width: 100%;
    margin-top: 23px;
    /* padding: 8px 54px 7px 20px; */
    font-size: 10px;
    line-height: 13px;
    font-family: 'Poppins-Regular';
    position: relative;
    padding: 9px 57px 7px 20px;
    word-break: break-all;
}

.inner-modal-copy .copy-img {
    position: absolute;
    right: 14px;
}

.swap-value-wrapper .dropdown-select .MuiSelect-select {
    background-color: #DCDCDC;
}

.metamask-network-footer .primary-btn-main,
.metamask-network-footer .primary-btn-main:hover {
    width: 100% !important;
}

.metamask-network-footer .primary-btn,
.metamask-network-footer .primary-btn:hover {
    width: 100%;
}

/* -----------------input End-------- */
/* Meta mask  End*/


.privat-sale-datepicker {
    background-color: #153341;
    border-radius: 4px;
}

.privat-sale-datepicker input {
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    padding: 10px 12px;
    color: #6d8d9d;
}

.privat-sale-datepicker svg path {
    fill: #6d8d9d;
}

.privat-sale-datepicker fieldset {
    border: none;
    /* border: 1px solid #d1d5db; */
    border-radius: 4px;
    width: 100%;
    /* color: #6b7280; */

}

.mr-none-right {
    margin-right: 0px !important;
}

.common-two-picker-date {
    border: 1px solid #1e566f;
}

.common-two-picker-date .MuiStack-root {
    padding-top: 0px;
}

.up-down-arrow svg {
    height: 18px;
    width: 18px;
    filter: brightness(0) invert(1);
}

.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg {
    fill: #3C6721 !important;
}

.admin-header-role {
    font-size: 12px !important;
    font-weight: 100 !important;
    color: #6ad3aa !important;
}
