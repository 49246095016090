* {
  box-sizing: border-box;
  padding: 0px;
  font-family: "Poppins-Regular";
  margin: 0px;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../fonts/Poppins/poppins-bold.ttf);
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(../fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../fonts/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: "Blinker-Regular";
  src: url(../fonts/Blinker/Blinker-Regular.ttf);
}

@font-face {
  font-family: "Blinker-SemiBold";
  src: url(../fonts/Blinker/Blinker-SemiBold.ttf);
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(../fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(../fonts/Roboto/Roboto-Bold.ttf);
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: space-between;
}

/* start input field design css */

.form-group .form-control input {
  border: 1px solid #262339;
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: #262339;
  color: #7a779c !important;
  /* color: #6b7280; */
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.form-group .from-control-password input::placeholder {
  color: #7a779c;
  font-size: 12px;
  line-height: 20px;
  opacity: 1;
}

.form-group .form-control input::placeholder {
  color: #7a779c;
  font-size: 12px;
  line-height: 20px;
  opacity: 1;
}

.form-group .MuiFormControl-root .MuiInputBase-colorPrimary.Mui-disabled .Mui-disabled {
  color: #7a779c !important;
  -webkit-text-fill-color: #000;
}

.form-group .MuiFormControl-root .MuiInputBase-colorPrimary.Mui-disabled .Mui-disabled svg {
  width: 16px;
}

.drop-form-control .MuiOutlinedInput-root.Mui-disabled .Mui-disabled {
  color: #6d8d9d !important;
  -webkit-text-fill-color: #6d8d9d;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.drop-form-control .MuiInputBase-root svg {
  color: #7a779c;
}

.form-control fieldset {
  border: none;
  /* border: 1px solid #d1d5db; */
  border-radius: 2px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d1d5db !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

/* end input field design css */

.page-border {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 20px;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  background: linear-gradient(180deg, #f9cc5d 0%, #fe806b 100%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  font-family: "Poppins-Regular";
  height: 40px;
  border-radius: 4px;
  text-transform: capitalize;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 10px 15px;
}

.border-btn-main .border-btn,
.border-btn-main .border-btn:hover {
  background: linear-gradient(180deg, #f9cc5d 0%, #fe806b 100%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  font-family: "Poppins-Regular";
  height: 40px;
  border-radius: 4px;
  text-transform: capitalize;
  max-width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: "Poppins-Regular";
  /* font-family: "Poppins-Regular"; */
}

/* start switch css */

.switch-main {
  margin-left: 20px;
}

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: "Poppins-Regular";
}

/* end switch css */

/* start pagination css */

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 27px; */
  padding: 10px 0px;
}

.pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  font-family: "Poppins-Regular";
  margin: 0 4px;
  border: 1px solid #3f395e;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: #3f395e;
  color: #ffffff;
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: #3f395e;
  color: #ffffff;
}

/* end pagination css */

/* start progrss bar css */

.progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: #114a65;
}

.progress-bar-content {
  margin-top: 30px;
}

.progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: #111827;
  font-family: "Poppins-Medium";
}

.progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: "Poppins-Medium";
}

/* end progrss bar css */

/* start error text css */

.error-text {
  color: red;
  font-size: 12px;
  line-height: 15px;
  font-family: "Poppins-Regular";
}

/* end error text css */

/* / start dropdown css / */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-select .MuiSelect-select {
  color:  #7a779c;
  /* color: #6b7280; */
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  display: flex;
  padding: 10px 15px;
  border-radius: 4px;
  background: #262339;
  border: 1px solid #262339;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.pb-0 {
  padding-bottom: 0 !important;
}

/* / end dropdown css / */

.images-upload-btn {
  position: relative;
  height: 100%;
  max-width: 200px;
}

.images-upload-btn .image-upload-btn,
.images-upload-btn .image-upload-btn:hover {
  background-color: #262339 !important;
  box-shadow: none;
  width: 100%;
  height: 100%;
  /* border: 1px solid #1e566f; */
  border: 1px solid #3f395e;
  border-radius: 10px;
  height: 200px;
}

.upload-img {
  height: 25px;
  width: 25px;
  object-fit: contain;
  border-radius: 10px;
  filter: brightness(0) saturate(100%) invert(20%) sepia(13%) saturate(1890%) hue-rotate(209deg) brightness(100%) contrast(88%);
}

.max-video-view-adver {
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.upload-img-slider {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  height: 200px;
}

/* .userdata-btn-flex svg path {
  fill: #102934;
} */

.custom-datepicker .MuiFormControl-root {
  width: 100%;
}

/* .userdata-btn-flex svg path {
  fill: #102934;
} */
.form-lable span {
  color: red;
  margin-left: 3px;
}

.table-td-Pending {
  color: #ffcb75;
}

.table-td-Inactive {
  color: red;
}

.table-td-active {
  color: #02d002;
}

/* .table-td-edit {
  color: #102934;
} */

.custom-datepicker .MuiStack-root {
  padding-top: 0px;
  overflow: inherit;
}

.custom-datepicker .date-adapter-picker .MuiInputBase-formControl {
  border: 1px solid #262339;
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: #262339;
  color: #7a779c;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.custom-datepicker .date-adapter-picker .MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.custom-datepicker .date-adapter-picker .MuiInputBase-formControl fieldset {
  border-color: transparent;
  /* border-color: #d1d5db; */
}

.custom-datepicker .MuiInputBase-input {
  padding: 0;
  color: #7a779c;
}

.custom-datepicker .date-adapter-picker .MuiInputBase-formControl .MuiInputBase-inputAdornedEnd {
  padding: 0px;
}

.deleteModel-heading {
  text-align: center;
  font-size: 18px;
  font-family: "Poppins-Regular";
  color: #fff;
  font-weight: 400;
}

/* RESEND CSS */

.box-login-text p {
  float: right;
  font-size: 12px;
  color: #ffffff;
  line-height: 18px;
  margin: 18px 0px 0px 0px;
  font-family: "poppins-bold";
}

.box-login-text {
  float: right;
}

.resend-otp-code {
  color: #ffffff !important;
}

.position-relative {
  position: relative;
}

.position-relative .document-close {
  position: absolute;
  right: 2px;
  z-index: 999;
  top: 5px;
  min-width: 30px;
}

.position-relative .document-close svg path {
  color: #6d8d9d;
}

.table-bg-main {
  /* border-radius: 15px;
  background-color: #0e2a36;
  border: none;
  padding: 20px; */
  padding: 20px;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(84, 99, 246, .1);
  border: none;
  position: relative;
  margin-bottom: 26px;
  background: #171423;
}

.table-bg-main.table-main-box {
  padding: 0;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(84, 99, 246, .1);
  border: 1px solid #3f395e;
  position: relative;
  background-color: #262339;
}

.view-ic path {
  fill: #fac060;
}

.edit-ic path{
  fill: #fd856a;
}

.delete-ic path{
  fill: #df0202;
}

.user-search-main .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1e566f !important;
}

.user-search-main .form-control fieldset,
.header-date-picker .form-control fieldset {
  border-color: #1e566f !important;
}

.cus-switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  /* color: #1e566f; */
  color: #181525;
}

.cus-switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #c7c9fd;
}

.cus-switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  /* background-color: #1e566f; */
  background-color: #c7c9fd;
}

.user-search-main .form-group .form-control input,
.header-date-picker .form-group .form-control input {
  border: 1px solid #1e566f;
}

.admin-login-main .form-group .form-control input {
  border: 1px solid #262339;
  background: #262339;
}

.admin-login-main .dropdown-select .MuiSelect-select {
  border: 1px solid #d1d5db;
}

.MuiMenu-paper {
  background: #0e2a36;
  border-color: #3f395e !important;
}

/* .advertiserlist-table-main .table .status-col .table-td-active {
  color: #ffffff;
  border-radius: 8px;
  padding: 8px 0;
  background: rgb(72 145 118 / 80%);
  text-align: center;
  max-width: 100px;
} */

/* .table-bg-main.table-main-box .advertiserlist-table-main .table .status-col .table-td-active {
  background-color: #3ed39a;
} */

/* .advertiserlist-table-main .table .status-col .table-td-Inactive {
  color: #9f3434;
  border-radius: 8px;
  padding: 8px 0;
  background-color: #9f3434;
  color: white;
  max-width: 100px;
  text-align: center;
} */

/* .advertiserlist-table-main .table .status-col .table-td-Pending {
  color: #9f3434;
  border-radius: 8px;
  padding: 8px 0;
  background: rgb(255 150 75 / 70%);
  color: white;
  text-align: center;
  max-width: 100px;
  width: 100px;
} */

/* .table-bg-main.table-main-box .advertiserlist-table-main .table .status-col .table-td-Pending {
  background-color: #ff6d4c;
} */

.text-center {
  text-align: center !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../images/svg/calendar.svg") no-repeat;
  /* background: url('../images/png/calendar.png') no-repeat; */
  width: 15px;
  background-size: 15px 15px;
  height: 15px;
  border-width: thin;
  margin-top: 4px;
}

.overlay-section {
  display: none !important;
}

.admin-dashboard-box {
  margin-bottom: 0px;
  z-index: 1;
  position: relative;
}

.admin-login-main .dropdown-select .MuiSelect-select {
  background: #ffffff;
}

.display-row-add-user .document-close {
  position: absolute;
  right: 0;
}

.document-close-set svg {
  color: #6d8d9d;
}

.input-box .form-control-eye input:-webkit-autofill {
  font-size: 12px;
  line-height: 20px;
  border: 1px solid #262339;
  font-family: "Poppins-Regular";
}

.cus-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-box-textarea .custom-textarea {
  height: 110px !important;
  resize: none;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: #153341;
  color: #6d8d9d;
  /* color: #6b7280; */
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

/* 
height: 110px;
width: 100%;
background-color: #153341;
border: none;
border-radius: 4px; */

.flex-search-details {
  display: flex;
  gap: 10px;
}

.flex-search-details .svg-download-csv {
  height: 18px;
}

.flex-search-details .fil_Button,
.flex-search-details .fil_Button:hover {
  background: linear-gradient(180deg, #f9cc5d 0%, #fe806b 100%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  font-family: "Poppins-Medium";
  height: 40px;
  border-radius: 5px;
  gap: 5px;
  text-transform: capitalize;
}

.textarea-filed {
  border: 1px solid #262339;
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: #262339;
  color: #7a779c;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  height: 150px !important;
  resize: none;
}

#menu-category .MuiMenu-list,
#menu-advertisement_type .MuiMenu-list {
  background: #231f34;
  /* border: 1px solid #1e566f; */
}

#menu-category .MuiMenu-paper,
#menu-advertisement_type .MuiMenu-paper {
  background: transparent !important;
}

#menu-category .MuiMenuItem-gutters, #menu-advertisement_type .MuiMenuItem-gutters {
  color: #656280 !important;
  font-size: 14px;
  font-family: 'Poppins-Regular';
}
.admin-sidebar-logo-main .custom-ui-btn,
.admin-sidebar-logo-main .custom-ui-btn:hover {
  z-index: 99;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0;
  outline: none;
  align-items: center;
  justify-content: center;
  background-color: #3f395e;
  color: #fff;
  border: 1px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
  margin: auto;
  transform: translateX(50%);
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  min-width: unset;
}

.sidebar-icon-content {
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
}

.custom-ui-btn .bergurmenu-icon {
  height: 16px;
  transform: rotate(179deg);
  filter: brightness(0) invert(1);
}

.admin-sidebar-list-main .admin-sidebar-listitem .title-sidebar-content {
  flex: 0 0 156px;
  font-size: 13px;
  font-family: "Poppins-Regular";
  color: #fff;
  padding: 5px 0px;
}

.dashboard-main .admin-sidebar-list-main .admin-sidebar-listitem.active::after {
  content: "";
  background: #fd8a69;
  height: 100%;
  width: 4px;
  position: absolute;
  left: -10px;
  border-radius: 10px;
}

.dashboard-left-main.admin-sidebar-deactive .admin-sidebar-list-main .admin-sidebar-listitem .sidebar-icon-content {
  flex: 0 0 30px;
  /* margin: 0px 14px; */
}

.dashboard-right-main.admin-sidebar-left {
  padding-left: 85px;
}

.mobile-logo-flexo {
  display: none;
}

.dashboard-left-main.admin-sidebar-deactive .dashboard-logo-flexo {
  display: none;
}

.dashboard-left-main.admin-sidebar-deactive .mobile-logo-flexo {
  display: block;
}

.dashboard-main .admin-sidebar-list-main .admin-sidebar-link.active .title-sidebar-content {
  color: #fd8a69;
}

/* .dashboard-left-main.admin-sidebar-deactive .sidebar-inner-drop-ul {
  padding: 0px 0px 0px 0px !important;
} */

/* .dashboard-left-main.admin-sidebar-deactive .sidebar-inner-drop-ul .sidebar-inner-dropli .admin-menu-link {
  color: #262626;
} */


.form-group.search-box-bg .form-control input {
  background-color: #262339;
  border: 1px solid #262339;
}

.export-btn-main.ml-export button {
  background-color: #171423;
}


/* New Top Mobile Header CSS */
.dashboard-top-header {
  display: none;
}

@media screen and (max-width: 991px) {
  .dashboard-top-header {
    display: block;
    background-color: #171423;
    z-index: 99;
    position: relative;
  }



  .dashboard-right-main.admin-sidebar-left {
    padding-left: 0 !important;
  }

  /* Mobile CSS */
  .dashboard-left-main.mobile-menu {
    display: block;
    top: 58px;
  }

  .dashboard-left-main.mobile-menu .admin-sidebar-logo-main {
    display: none;
  }

  .dashboard-left-main.mobile-menu.admin-sidebar-deactive {
    width: 250px;
    max-width: 250px;
  }

  .dashboard-left-main.mobile-menu.admin-sidebar-deactive .admin-sidebar-list-main .admin-sidebar-listitem .title-sidebar-content {
    opacity: 1;
  }

  .dashboard-left-main.mobile-menu.admin-sidebar-deactive .admin-sidebar-list-main .admin-sidebar-listitem .sidebar-icon-content {
    margin: 0px 4px
  }

  .dashboard-left-main.mobile-menu.admin-sidebar-deactive .admin-sidebar-listitem:hover .title-sidebar-content {
    width: unset;
    opacity: 1;
    background: transparent;
    max-width: none;
    padding: 5px 0px;
    border-radius: 0;
    transform: none;
    box-shadow: none;
    white-space: unset;
  }

  .user-details-show {
    display: none;
  }

  .dashboard-left-main.mobile-menu.admin-sidebar-deactive .sidebar-inner-drop-ul {
    display: block;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    right: 0;
    top: 0;
    border: 0px;
  }

  .dashboard-left-main.mobile-menu .sidebar-inner-drop-ul {
    padding: 0px 0px 0px 29px !important;
  }

  .dashboard-left-main.mobile-menu.admin-sidebar-deactive .up-down-arrow {
    display: block;
  }

  .sidebar-inner-drop-ul .sidebar-inner-dropli .admin-menu-link {
    color: #c7c9fd !important;
  }

  .dashboard-top-header.mobile-logo .admin-sidebar-logo {
    transition: 0.5s all;
    margin-left: 10px;
    height: 24px;
  }

}

/* End Top Mobile Header CSS */

.graph-box-cont .graph-title {
  font-size: 22px;
  line-height: 39px;
  font-family: 'Poppins-Regular';
  font-weight: 400;
  color: #fff;
}

.graph-advertisement .graph-box {
  background: #262339;
}

.graph-box {
  padding: 0;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(84, 99, 246, .1);
  border: none;
  position: relative;
  padding: 16px;
  margin-bottom: 26px;
  background: #171423;
}

.modal-delete .delete-modal-inner-main:focus-visible{
  border: none;
  outline: none;
}

.cancel-modal-btn {
  position: absolute;
  right: 0;
  top: 20px;
}

.mt-video-btn{
  margin-top: 23px;
}

.mt-video-btn .w-100btn, .mt-video-btn .w-100btn:hover{
  width: 100%;
  max-width: unset;
}

.admin-login-inner .admin-signup p {
  color: #7a779c;
  font-family: "Poppins-Regular";
}
.MuiTooltip-popper{
  margin-top: -20px !important;
}

.radio-group-controls span {
  color: #7a779c;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 4px;
  border: 1px solid #7a779c;
  font-family: "Poppins-Regular";
  padding: 10px 15px;
  font-size: 14px;
    line-height: 20px;
  height: unset;
  text-transform: capitalize;
}

.form-control .Mui-disabled input{
  color: #7a779c !important;
  -webkit-text-fill-color: #7a779c !important;
  background: #262339;
}

 .form-group .date-adapter-picker .Mui-disabled input{
  color: #7a779c !important;
  -webkit-text-fill-color: #7a779c !important;
  background: transparent;
}

.form-group .date-adapter-picker .Mui-disabled{
  background: #262339;
}

.form-group textarea:disabled{
  color: #7a779c !important; 
  -webkit-text-fill-color: #7a779c !important;
  background:#262339;
}

.common-drawer-toggle .MuiDrawer-paper {
  max-width: 350px;
}

.common-main-drawer {
  height: 100%;
  /* padding: 15px; */
}

.drawer-header .common-filter-title {
  font-size: 20px;
  font-family: 'Poppins-Medium';
  line-height: 25px;
  color: #fff;
}

.drawer-header {
  margin-bottom: 15px;
  padding: 15px 15px;
  border-bottom: 1px solid #3f395e;
}

.common-main-drawer .common-drawer-details .common-pd-drawer .drawer-details-hgt {
  padding: 15px;
  height: calc(100vh - 142px);
  overflow: auto;
}

.common-btn-details .end-justify-content {
  justify-content: end;
}

.common-btn-details .flex-gap-footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.common-btn-details .btn-secondary {
  background: #3f395e;
  color: #fff;
  border-radius: 7px;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 18px;
  padding: 10px 20px;
}

.common-btn-details .btn-secondary.Mui-disabled{
color: rgb(255 255 255 / 48%);
}

.common-input-field .input-common-field .common-textfield-content input {
  padding: 9px 15px;
  background: #262339;
  border-radius: 8px;
  font-size: 14px;
  color: #7a779c;
  line-height: 18px;
}

.common-input-field .input-common-field .common-textfield-content fieldset {
  border-color: #262339;
  border-width: 1px;
  border-radius: 8px;
}

.common-input-field .input-common-field .common-textfield-content input.Mui-disabled {
  -webkit-text-fill-color: #ffffff99;
}

.common-textfield-content{
  background: #262339;
    border-radius: 9px;
}

 .input-w-100 {
  width: 100%;
}

.common-main-drawer .common-drawer-details .common-pd-drawer .drawer-footer {
  padding: 15px;
  border-top: 1px solid #3f395e;
}

.common-input-field .common-label-details {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 3px;
  margin-top: 0px;
  color: #fff;
}

.m-width-unset{
  min-width: max-content !important;
}

.deposit-modal-heading{

  font-family: 'Poppins-Medium';
  color: #fff;
}
.delete-modal-inner-main .modal-heading-deposit{
  margin: 20px 0;
  font-size: 20px;
}

.category-img-icon{
  max-width: 200px;
}

.MuiInputAdornment-root svg.MuiSvgIcon-root path{
  fill: #fff;
}

.action-tab svg {
  color: white;
}

.control-label-common .MuiTypography-root {
  font-family: 'Poppins-Regular';
  color: #7a779c;
}

.control-label-common .MuiFormControlLabel-label.Mui-disabled {
  color: #7a779c;
}

.MuiModal-backdrop{
  background: transparent linear-gradient(to top, #1714232e 0%, #1714232e 100%);
}

.common-pd-drawer{
  background: #171423;
}

.deposit-table-main .table{
  min-width: 1014px !important;
}

.pagination-main .MuiTablePagination-selectLabel,
.pagination-main .MuiTablePagination-select,
.pagination-main .MuiSvgIcon-root,
.pagination-main .MuiTablePagination-displayedRows {
  color: #fff;
  font-family: 'Poppins-Regular';
}

.MuiPopover-paper{
  background-color: #232035;
    color: rgb(255 255 255);
}


.ck.ck-toolbar.ck-toolbar_grouping {
  background: #262339;
  border: 1px solid #262339 !important;
}

.ck.ck-button.ck-on, .ck-custom-editor-main a.ck.ck-button.ck-on{
  background: #262339;
}

.ck-custom-editor-main .ck.ck-list {
  background: #262339;
}

 .ck.ck-dropdown__panel{
  border-color: #262339;
  background-color: #262339;
}

 .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
 .ck.ck-editor__main>.ck-editor__editable.ck-focused {
  background: #262339;
  border-color: #262339;
  color: #fff;
  min-height: 300px;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #fff;
}

 .ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #262339;
}

 .ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: #262339;
}

ul.ck.ck-reset.ck-list {
  background: #262339;
}

.ck.ck-list__item .ck-button.ck-on {
  background: #262339;
}

.ck.ck-list__item .ck-button.ck-on:focus:not(.ck-switchbutton):not(.ck-disabled) {
  border: 1px solid #3e3e3e;
  box-shadow: none;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button.ck-off:active, .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on:active {
  box-shadow: none !important;
  border: none;
  background: #161616;
  color: white;
}

.ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover, .ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background: #161616;
}

.ck.ck-button.ck-on, a.ck.ck-button.ck-on {
  color: #ffffff;
}

.ck-editor__editable .ck-list-bogus-paragraph{
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  color: #7a779c !important;
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border: solid #262339;
  border-bottom: 1px solid #3c345d;
}

.deposite-modal-main .modal-heading-deposit {
  margin-top: 0px;
}

.close-icon-modal {
  height: 20px;
}

.table-td-running{
  color: #ffa58c; 
}

.table-td-pause{
  color: #ffae00;
}
/* .add-user-input {
  color: #d32f2f;
} */
 /* .file-upload-btn-main .MuiFormHelperText-root{
  color: #d32f2f;
 } */
 /* .form-group .error-text-custom {
      color: #d32f2f;
  } */
  .flex-advertisemant-main {
    display: flex;
    justify-content: space-between;
  }

  .MuiTooltip-arrow:before,
  .MuiTooltip-tooltipArrow{
    background-color: #171423 !important;
  }

  

  .MuiTooltip-tooltip {
    margin-top: 27px !important;
}

.max-gas-fees{
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  max-width: 230px;
}
.custom-range-picker-user label.Mui-focused , .custom-range-picker-user label.MuiFormLabel-filled, div[style*="pointer-events: none; color: rgba(130, 130, 130, 0.62);"] {
  display: none !important;
}

.custom-range-picker-user label {
  margin-top: -4px;
}

.custom-range-picker-user .MuiInputBase-root input{
  font-size: 14px;
}

.card-main-colleft .graph-box-card-pd {
  padding: 20px 0px 0px 0px;
}

.custom-modal-details .custom-modal-datepicker {
  max-width: 450px !important;
}
.custom-range-picker-user .MuiMultiInputDateRangeField-separator {
    color: #403a60;
}

.custom-range-picker-user fieldset {
  border: none;
}

.MuiPickersArrowSwitcher-button {
  padding: 0px;
}

.MuiPickersArrowSwitcher-root {
  padding: 12px;
}

.MuiDateRangePickerDay-root {
  height: 30px;
}

.MuiDayCalendar-slideTransition {
  min-height: 194px !important;
  overflow: auto;
}

.MuiPickersPopper-root .MuiDateRangePickerDay-rangeIntervalPreview button {
  color: #7a779c;
  height: 30px;
  width: 36px;
  padding: 0px;
  border-color: #c97c62;
} 

.MuiDateRangePickerDay-day:not(.Mui-selected){
  border: none !important;
}

.MuiPickersPopper-root .MuiDateRangePickerDay-rangeIntervalPreview button.Mui-selected ,
.MuiDateRangePickerDay-day:hover{
  background-color: transparent !important;
  color: #ffffff !important;
}

.MuiDateRangePickerDay-day:hover{
  border: none !important;
}

.MuiDateRangePickerDay-root {
  height: 32px;
}

.MuiTypography-subtitle1 {
  font-size: 14px;
  font-family: 'Poppins-Regular';
}

.box-show-dash-prices {
  border: 1px solid #3f395e;
  border-radius: 16px;
}


.box-show-dash-prices .show-prices-pd {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 8px;
}

.flex-name-prices {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-name-prices .admin-dash-right {
  width: unset;
}

.flex-name-prices .admin-dash-text {
  color: #fff;
  font-family: 'Poppins-Regular';
  font-size: 14px;
}

.show-prices-list .admin-dash-price {
  font-size: 15px;
  color: #fd7e52;
  background: #3c2d3e;
  padding: 5px;
  border-radius: 5px;
  font-family: 'Poppins-Regular';
  /* position: absolute;
  top: -12px;
  right: 27px; */
  box-shadow: 0 0 1rem #fd7e5229;
}

.flex-name-prices .dash-icons {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(213deg) brightness(102%) contrast(102%);
}


.grid-line-ul .grid-line-li:nth-child(2) .show-prices-list .admin-dash-price {
  color: #face76;
  background: #877655;
  box-shadow: 0 0 1rem #face764f;
}

.grid-line-ul .grid-line-li:nth-child(3) .show-prices-list .admin-dash-price {
  color: #77bc9b;
  background: #4a6257;
  box-shadow: 0px 0px 1rem #77bc9b4a;
}

.grid-line-ul .grid-line-li:nth-child(4) .show-prices-list .admin-dash-price {
  color: #938fb6;
  background: #67666b;
  box-shadow: 0px 0px 1rem #938fb654;
}

.card-main-colleft .pt-card-line {
  padding-top: 10px;
}