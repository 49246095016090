/* / Loader / */
.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1301;
  position: fixed;
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
}

.configure-border-1 {
  width: 220px;
  height: 70px;
  padding: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 3px solid #ebcf7f; */
  /* animation: configure-clockwise 3s ease-in-out 0s infinite alternate; */
}

.configure-border-2 {
  width: 60px;
  height: 60px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #18290D; */
  background: linear-gradient(45deg, #181524, #3f395e);
  /* transform: rotate(45deg); */
  /* animation: configure-xclockwise 3s ease-in-out 0s infinite alternate; */
}

/* .custom-loader-mvp .head-logo-icon {

} */

.spinner-box-custom {
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;

}

.custom-loader-mvp {
  width: 200px;
  height: 70px;
}

.custom-loader-mvp .head-logo-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.configure-core {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }

  25% {
    transform: rotate(-45deg);
  }

  50% {
    transform: rotate(-135deg);
  }

  75% {
    transform: rotate(-225deg);
  }

  100% {
    transform: rotate(-315deg);
  }
}

.loader {
  border: 2px solid #d3b15f;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}