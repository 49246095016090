/* start user list page */

.userdata-btn-flex {
  display: flex;
  /* justify-content: end; */
}

.userdata-btn-flex button {
  min-width: 30px;
}

.userlist-data {
  display: flex;
  align-items: center;
  width: 100%;
}

.userlist-img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  object-fit: cover;
}

.advertiserlist-table-main .table {
  margin: 0px;
}

.advertiserlist-table-main .table .table-th {
  padding: 8px 8px 8px 8px;
}

.advertiserlist-table-main .table .table-td {
  padding: 8px 8px 8px 8px;
}

/* .advertiserlist-table-main .table .table-th:nth-child(1),
.advertiserlist-table-main .table .table-td:nth-child(1) {
  width: 10%;
}

.advertiserlist-table-main .table .table-th:nth-child(2),
.advertiserlist-table-main .table .table-td:nth-child(2) {
  width: 12%;
}

.advertiserlist-table-main .table .table-th:nth-child(3),
.advertiserlist-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.advertiserlist-table-main .table .table-th:nth-child(4),
.advertiserlist-table-main .table .table-td:nth-child(4) {
  width: 15%;
}

.advertiserlist-table-main .table .table-th:nth-child(5),
.advertiserlist-table-main .table .table-td:nth-child(5) {
  width: 10%;
}

.advertiserlist-table-main .table .table-th:nth-child(6),
.advertiserlist-table-main .table .table-td:nth-child(6) {
  width: 10%;
}

.advertiserlist-table-main .table .table-th:nth-child(7),
.advertiserlist-table-main .table .table-td:nth-child(7) {
  width: 10%;
} */

.userlist-inner-btn-flex {
  display: flex;
}

.down-icon,
.plus-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 9px;
}

.export-btn-main .export-btn,
.export-btn-main .export-btn:hover {
  margin-right: 10px;
}

.user-search-box {
  position: relative;
  margin-right: 10px;
}

.user-search-box .form-control input::placeholder {
  color: #7a779c;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.search-grey-img {
  position: absolute;
  left: 13px;
  top: 14px;
  height: 14px;
  width: 14px;
  margin-right: 8px;
  filter: brightness(0) saturate(100%) invert(54%) sepia(15%) saturate(765%) hue-rotate(205deg) brightness(86%) contrast(82%);
}

.user-search-box .form-control input {
  padding: 10px 35px;
}

.user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.userlist-btn-flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.user-list-flex .user-list-page-title {
  margin-bottom: 0px;
}

/* end user list page */

/* start add user modal css */

.span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.modal .modal-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.modal .add-user-modal-inner-main {
  padding: 24px 24px 30px 24px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* end add user modal css */

/* start delete user modal css */

.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  padding: 40px 24px 40px 24px;
  max-width: 330px;
  width: 100%;
  border: 1px solid #171423;
  border-radius: 10px;
  background: #171423;
  position: relative;
}

.delete-modal-inner-main .delete-modal-title {
  color: #111827;
  font-size: 28px;
  line-height: 42px;
  font-family: "Poppins-Medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: #6b7280;
  margin-bottom: 26px;
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}



.delete-modal-btn-flex .modal-cancel-btn {
  color: #7a779c;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background-color: #e4004d;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

/* start filter css */

.filter-main {
  position: relative;
}

.filter-main .MuiDrawer-paper {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
}

.filter-header {
  background-color: #07415d;
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;
  align-items: center;
}

img.filter-close-icon {
  height: 21px;
  width: 21px;
  object-fit: contain;
}

.filter-header .filter-title {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-family: "Poppins-Regular";
}

.filter-inner-main {
  padding: 20px 25px;
  background-color: #fff;
}

.filter-btn-main {
  display: flex;
  justify-content: flex-end;
}

.filter-btn-main .filter-cancel-btn {
  min-width: 90px;
  margin-right: 20px;
}

.filter-btn-main .filter-btn,
.filter-btn-main .filter-btn:hover {
  max-width: 90px;
}

.filter-footer {
  border-top: 1px solid #d1d5db;
  padding: 15px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.filter-checkbox-main .checkbox-lable {
  margin-right: 20px;
}

/* end filter css */
.admin-menu-main ul,
.cus-menu-ul ul {
  display: flex;
  gap: 8px;
  padding: 0px 4px;
  background: #262339;
}

.menu-option-li,
.cus-menu-li {
  padding: 0 !important;
}

.menu-option-li button,
.cus-menu-li button{
  /* background-color: red; */
  min-width: 36px;
}

.admin-dash-box .advertiser-table-main .table {
  min-width: 1030px !important;
}


