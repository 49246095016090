/* start admin dashboard page */

.dashboard-content .admin-page-title {
    color: #111827;
    font-size: 26px;
    line-height: 39px;
    margin-bottom: 20px;
}

.admin-dashboard-inner-box {
    border: 1px solid #D1D5DB;
    max-width: 100%;
    width: 100%;
    height: 82px;
    border-radius: 6px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.admin-dashboard-inner-box .admin-dash-text {
    color: #6B7280;
    font-size: 12px;
    line-height: 18px;
}

.admin-dashboard-inner-box .admin-dash-price {
    color: #111827;
    font-size: 30px;
    line-height: 46px;
}

.dash-icons {
    height: 30px;
    width: 30px;
    object-fit: contain;
}


.page-table-main .table-container {
    padding-bottom: 7px;

    box-shadow: none;
    overflow: auto;
    max-width: calc(100vw - 0px);
}

.page-table-main .table {
    min-width: 1100px;
}

.page-table-main .table-th {
    font-size: 12px;
    line-height: 18px;
    color: #111827;
    padding: 10px 10px 10px 0px;
    border-bottom: 1px solid #D1D5DB;
}

.page-table-main .table .table-td:first-child {
    color: #111827;
}

.page-table-main .table-td {
    font-size: 12px;
    line-height: 18px;
    color: #6B7280;
    padding: 10px 10px 10px 0px;
    border-bottom: 1px solid #D1D5DB;
}

/* 
.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
    width: 5%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
    width: 5%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
    width: 7%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
    width: 7%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
    width: 7%;
} */

.admin-sub-title-main .admin-sub-title {
    color: #111827;
    font-size: 20px;
    line-height: 30px;
}

.view-btn-main .view-order-btn {
    font-size: 12px;
    line-height: 18px;
}

.view-btn-main .view-order-btn:hover {
    font-size: 12px;
    line-height: 18px;
    background-color: #114A65;
    color: #FFFFFF;
}



.admin-dashboard-table-row {
    border-bottom: 1px solid #D1D5DB;
    padding: 20px 19px;
}

.admin-dash-box {
    border-radius: 8px;
    /* border: 1px solid #D1D5DB; */
    padding: 0px 0px 20px 0px;
}

.admin-dashboad-row {
    margin-bottom: 24px;
}

.display-row>* {
    padding: 0px 8px;
}

.display-row {
    margin-left: -8px;
    margin-right: -8px;
}




.table-lable-main {
    font-size: 12px;
    line-height: 18px;
}

.paid-lable-dots {
    background-color: #04AB47;
}

.paid-lable {
    color: #04AB47;
}

.table-lable-dots {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin-right: 4px;
}

.table-lable-flex {
    display: flex;
    align-items: center;
}

.failed-lable-dots {
    background-color: #E4004D;
}

.failed-lable {
    color: #E4004D;
}

.pending-lable-dots {
    background-color: #f39c12;
}

.pending-lable {
    color: #f39c12;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
    width: 2px;
    height: 5px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
    background: #D1D5DB;
    border-radius: 5px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
    background: #8888885c;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* end admin dashboard page */



.deposit-group-btn .deposit-btn-main {
    display: flex;
    column-gap: 10px;
}

.deposit-group-btn {
    padding: 0px !important;
    display: flex;
    justify-content: flex-end;
}

.flex-dash-advertisement {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.deposit-btn-main {
    display: flex;
    align-items: center;
    gap: 10px;
}

.flex-dash-advertisement .admin-page-title.main-heading {
    margin-bottom: 0px;
}

.deposit-btn-main .login-btn-main .login-btn {
    margin-top: 0px;
}

.custom-dropdown-property .MuiSelect-select {
    width: 165px;
    padding: 10px 15px 10px 35px !important;
}

.MuiPaper-elevation ul {
    background: #231f34;
    color: #7a779c;
}

.custom-drop-form-control {
    display: flex;
    gap: 20px;
    align-items: center;
}

.custom-range-picker-user .MuiFormControl-root {
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    display: flex;
    padding: 0px;
    border-radius: 4px;
    background: #262339;
    border: 1px solid #262339;
}

.custom-range-picker-user label {
    font-size: 12px;
    color: #7a779c !important;
}

.custom-range-picker-user .MuiInputBase-root input {
    padding: 9.5px 15px;
    color: #7a779c;
}

.custom-drop-form-control .MuiStack-root {
    padding-top: 0px;
}

.MuiPickersPopper-root .MuiPaper-elevation {
    background-color: #231f34;
    color: #7a779c;
}

.MuiDateRangeCalendar-monthContainer .MuiPickersArrowSwitcher-root svg {
    fill: #7a779c;
}

.MuiPickersPopper-root .MuiDayCalendar-header span {
    color: #7a779c;
}

.MuiPickersPopper-root .MuiDateRangePickerDay-rangeIntervalPreview button {
    color: #7a779c;
}

.MuiPickersPopper-root .MuiDateRangePickerDay-rangeIntervalPreview button.Mui-selected {
    background-color: #7a779c;
    color: #D1D5DB;
}

.advertiserlist-table-main-costom table {
    min-width: 850px !important;
}

.custom-drop-form-control .main-select-day {
    position: relative;
}

.costom-select-calender-icon {
    position: absolute;
    top: 8px;
    bottom: 0px;
    width: 20px;
    left: 4px;
    right: 0;
}

.costom-select-calender-icon svg {
    fill: #7a779c;
}
.apexcharts-menu-icon {
    display: none;
}
.custom-range-picker-button-main{
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
}

.custom-range-picker-button-main .custom-range-picker-button-close {
        background: linear-gradient(180deg, #f9cc5d 0%, #fe806b 100%);
            width: 82px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 14px;
            line-height: 19px;
            font-family: "Poppins-Regular";
            height: 40px;
            border-radius: 4px;
            text-transform: capitalize;
            max-width: 100%;
            -webkit-border-radius: 4px;
}