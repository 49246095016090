@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
      .admin-dashboard-box {
            margin-bottom: 16px;
      }
}

/* phone and tablets */
@media screen and (max-width: 991px) {
      .admin-dashboard-box {
            margin-bottom: 16px;
      }

      .dashboard-left-main {
            flex: 0 1;
            left: -250px;
            display: none;
      }

      .dashboard-left-main.admin-sidebar-deactive {
            left: 0px;
      }

      .dashboard-right-main {
            padding-left: 0px;
      }

      .dashboard-top-header.admin-sidebar-deactive{
        width: 100%;
        max-width: 100%;
      }

      .dashboard-main-overlay .overlay-section {
            display: block !important;
      }

      .overlay-section {
            position: fixed;
            right: 0;
            max-width: 100%;
            background: rgb(20 19 19 / 50%);
            top: 0;
            bottom: 0;
            width: 100%;
            transition: all 0.3s;
            z-index: 99;
            display: none;
      }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
      .overlay-section.active {
            display: none;
      }
/* 
      .dashboard-content .admin-page-title {
            margin-bottom: 20px;
      } */

      .body-overflow {
            overflow: hidden;
      }

      .user-list-flex {
            /* max-width: max-content; */
            width: unset;
            /* align-items: flex-start; */
            /* flex-direction: column; */
      }

      .res-direction-col {
            flex-direction: column;
      }

      .res-flex {
            flex-wrap: nowrap;
      }

      .admin-sidebar-logo-main .sidebar-close-btn {
            display: flex;
      }

      .close-icon {
            height: 23px;
            width: 23px;
            object-fit: contain;
            display: block;
      }

      .admin-sidebar-logo-main {
            display: flex;
            justify-content: space-between;
      }

      .res-admin-sidebar-logo {
            order: initial;
            margin: initial;
      }

}

/* phone */
@media (max-width: 767px) {
      .graph-box text {
            font-size: 12px;
        }
      .flex-search-details {
            justify-content: end;
        }

        .search-box-bg{
            position: relative;
        }
        
      /* .search-box-bg, .export-btn-main{
            width: 100%;
      } */
      .export-btn-main .flex-search-details .fil_Button, .export-btn-main .flex-search-details .fil_Button:hover{
            max-width: 100%;
      }
      .admin-sidebar-logo-main .sidebar-close-btn {
            display: flex;
      }

      .dashboard-containt-main {
            padding: 24px 15px 24px 15px;
      }

      .admin-dashboard-inner-box {
            max-width: 100%;
      }

      .dashboard-content .admin-page-title {
            font-size: 20px;
            line-height: 25px;
      }

      .admin-dashboard-inner-box .admin-dash-price {
            font-size: 20px;
            line-height: 25px;
      }


      .export-btn-main .export-btn,
      .export-btn-main .export-btn:hover {
            margin-right: 0px;
      }
/* 
      .dashboard-content .user-list-page-title {
            margin-bottom: 20px;
      } */

      .bergurmenu-icon {
            height: 20px;
            object-fit: contain;
            width: 20px;
      }

      .admin-header-icon-box {
            margin-right: 15px;
      }

      .admin-header-row .bergur-button {
            padding: 0px;
            min-width: 37px;
      }

      .dashboard-left-main {
            flex: 0 1;
            left: -250px;
      }

      .dashboard-left-main.admin-sidebar-deactive {
            left: 0px;
            top: 58px;
      }

      .dashboard-right-main {
            padding-left: 0px;
      }

      .admin-sidebar-logo-main {
            display: flex;
            justify-content: space-between;
      }

      .close-icon {
            height: 20px;
            width: 20px;
            object-fit: contain;
            display: block;
      }

      .admin-sidebar-logo {
            order: initial;
            margin: initial;
      }

      .admin-header-main.active .admin-sidebar-logo {
            opacity: 1;
            display: block;
            height: 24px;
            margin-left: 10px;
            /* order: 1; */
      }

      .admin-header-logo-main {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
        }

      .admin-header-main.admin-header-deactive .admin-sidebar-logo {
            opacity: 1;
            margin-left: 10px;
            height: 24px;
      }

      .admin-header-main {
            left: 0;
      }

      .admin-header-deactive .admin-header-logo-main {
            flex-direction: row-reverse;
      }

      .filter-main .MuiDrawer-paper {
            min-width: 300px;
            max-width: 300px;
      }

      .progress-bar-text {
            right: 0px;
            top: -23px;
      }

      .body-overflow {
            overflow: hidden;
      }

      .header-sidebar-logo {
            order: 2;
      }

      .modal .modal-inner {
            max-width: 350px;
      }

      .header-date-picker {
            width: 100%;
            margin: 0 0 10px 0 !important;
      }

      .user-search-main,
      .userlist-btn-flex .adduser-btn-main {
            width: 100%;
      }


      .dashboard-main-overlay .overlay-section {
            display: block !important;
      }

      .overlay-section {
            position: fixed;
            right: 0;
            max-width: 100%;
            background: rgb(20 19 19 / 50%);
            top: 58px;
            bottom: 0;
            width: 100%;
            transition: all 0.3s;
            z-index: 99;
            display: none;
      }

}

@media (max-width: 650px){
      
      .flex-advertisemant-main {
            flex-direction: column;
            gap: 5px;
        }
        
        .flex-advertisemant-main .custom-drop-form-control {
            width: 100%;
            display: unset;
        }
        
        .flex-advertisemant-main .custom-drop-form-control .drop-select {
            width: 100%;
        }
        
        .flex-advertisemant-main .custom-drop-form-control .drop-select .MuiSelect-select {
            width: 100%;
        }
      .user-list-flex{
            flex-direction: column;
            align-items: self-start;
      }
      .dashboard-content .admin-page-title{
            margin-bottom: 20px;
      }
      .flex-search-details{
            flex-direction: column;
            width: 100%;
      }
      .userlist-btn-flex,.export-btn-main.ml-export button{
            width: 100%;
      }
      .export-btn-main.ml-export button, .btn-main-primary button{
            max-width: unset !important;
      }
}

@media (max-width: 430px){
      .flex-name-prices{
            flex-direction: column;
            align-items: start;
      }
      .graph-box text {
            font-size: 10px;
        }

        .flex-advertisemant-main {
            flex-direction: column;
            gap: 5px;
        }
        
        .flex-advertisemant-main .custom-drop-form-control {
            width: 100%;
            display: unset;
        }
        
        .flex-advertisemant-main .custom-drop-form-control .drop-select {
            width: 100%;
        }
        
        .flex-advertisemant-main .custom-drop-form-control .drop-select .MuiSelect-select {
            width: 100%;
        }
}