/* start admin dashboard page */

.dashboard-content .admin-page-title {
    color: #262626 !important;
    font-size: 22px;
    line-height: 39px;
    font-family: 'Poppins-Regular';
    margin-bottom: 20px;
}

.admin-dashboard-inner-box {
    border: 1px solid #D1D5DB;
    max-width: 100%;
    width: 100%;
    /* height: 120px; */
    border-radius: 15px;
    padding: 15px 20px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}



.admin-dashboard-inner-box .admin-dash-text {
    color: #6B7280;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Poppins-Regular';
}

.admin-dashboard-inner-box .admin-dash-price {
    color: #111827;
    font-family: Poppins-Medium;
    font-size: 20px;
    line-height: 46px;
    max-width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dash-icons {
    height: 45px;
    width: 45px;
    object-fit: cover;
}


.img-icons {
    height: 46px;
    width: 46px;
    /* height: 50px;
    width: 50px; */
    object-fit: contain;
}


.page-table-main .table-container {
    padding-bottom: 7px;
    background: #262339;
    border-radius: 1rem;
    box-shadow: none;
    overflow: auto;
    max-width: calc(100vw - 0px);
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.page-table-main .table {
    /* margin: 0px 19px; */
    min-width: 1100px;
    width: -webkit-fill-available;
    ;
}

.page-table-main .table-th {
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    font-family: 'Poppins-Medium';
    padding: 10px 10px 10px 0px;
    /* border-bottom: 1px solid #D1D5DB; */
    border-bottom: 1px solid #203a44;
    text-transform: capitalize;
}

.table-bg-main.table-main-box table {
    margin: 16px !important;
    background: #262339;
}

.table-bg-main.table-main-box .page-table-main .table-th {
    font-size: 14px;
    color: #adafc2;
    border-bottom: 0;

}

.table-bg-main.table-main-box .page-table-main .table-td {
    border-bottom: 1px solid #3f395e;
    color: #fff;
}

.page-table-main .table .table-td:first-child {
    color: #ffffff;
    /* color: #9fbac3; */
}

.page-table-main .table-td {
    font-size: 12px;
    line-height: 18px;
    color: #9fbac3;
    font-family: 'Poppins-Regular';
    padding: 10px 10px 10px 0px;
    border-bottom: 1px solid #203a44;
}

/* 
.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
    width: 5%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
    width: 5%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
    width: 4%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
    width: 7%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
    width: 7%;
} */

.admin-sub-title-main .admin-sub-title {
    color: #111827;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Poppins-Medium';
}

.view-btn-main .view-order-btn {
    font-size: 12px;
    line-height: 18px;
}

.view-btn-main .view-order-btn:hover {
    font-size: 12px;
    line-height: 18px;
    background-color: #114A65;
    color: #FFFFFF;
}



.admin-dashboard-table-row {
    border-bottom: 1px solid #D1D5DB;
    padding: 20px 19px;
}

.admin-dash-box {
    border-radius: 8px;
    /* border: 1px solid #D1D5DB; */
    padding: 0px 0px 20px 0px;
}

.admin-dashboad-row {
    margin-bottom: 24px;
}

.display-row>* {
    padding: 0px 8px;
}

.display-row {
    margin-left: -8px;
    margin-right: -8px;
}




.table-lable-main {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 18px;
}

.paid-lable-dots {
    background-color: #04AB47;
}

.paid-lable {
    color: #04AB47;
}

.table-lable-dots {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    margin-right: 4px;
}

.table-lable-flex {
    display: flex;
    align-items: center;
}

.failed-lable-dots {
    background-color: #E4004D;
}

.failed-lable {
    color: #E4004D;
}

.pending-lable-dots {
    background-color: #f39c12;
}

.pending-lable {
    color: #f39c12;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar,
.admin-sidebar-list-main::-webkit-scrollbar,
.dashboard-containt-main::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track,
.admin-sidebar-list-main::-webkit-scrollbar-track,
.dashboard-containt-main::-webkit-scrollbar-track {
    background: #3f395e;
    border-radius: 5px;
    margin: 0px 10px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb,
.admin-sidebar-list-main::-webkit-scrollbar-thumb,
.dashboard-containt-main::-webkit-scrollbar-thumb {
    background: #171424;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover,
.admin-sidebar-list-main::-webkit-scrollbar-thumb:hover,
.dashboard-containt-main::-webkit-scrollbar-thumb:hover {
    background: #171424;
}

/* end admin dashboard page */
.admin-dash-right {
    /* idth: 60px;
    height: 60px;w */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* background-color: rgb(255 224 121 / 11%); */
    border-radius: 12px;
    margin-right: 0px;
    gap: 15px;
}

.dashboard-main .admin-dashboard-inner-box.admin-dash-new-box .admin-dash-right {
    /* width: 56px;
    height: 56px;
    background-color: #171424;
    border-radius: 12px;
    margin-right: 0px; */
    margin-bottom: 8px;
}

/* .dashboard-main .admin-dashboard-inner-box.admin-dash-new-box .admin-dash-right img {
    filter: brightness(0) invert(1);
} */

.card-sec-main .grid-column:nth-child(2) .admin-dash-right {
    background-color: rgb(22 98 180 / 11%);
}

.card-sec-main .grid-column:nth-child(3) .admin-dash-right {
    background-color: rgb(204 107 22 / 11%);
}

.card-sec-main .grid-column:nth-child(4) .admin-dash-right {
    background-color: rgb(0 172 120 / 11%);
}

.graph-advertisement {
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(84, 99, 246, .1);
    border: none;
    position: relative;
    padding: 16px;
    margin-bottom: 26px;
    background: #171423;
}

text {
    fill: #fff;
}

.apexcharts-legend-text {
    color: #fff !important;
}

.copy-text-bg-main {
    width: 100%;
    /* height: 100%; */
    transform: matrix(1, 0.04, -0.09, 1, 0, 0);
    background: linear-gradient(115deg, rgb(249 204 93 / 15%) 0%, rgb(254 128 107 / 15%) 47%, rgb(76 181 140 / 15%) 100%);
    position: absolute;
    border-radius: 15px;
    min-height: 109px;
    top: -5px;
    transition: 0.5s all ease-in-out;
}

.dashboard-back-control:hover .copy-text-bg-main {
    transform: scale(1.1);
}

.dashboard-back-control {
    position: relative;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}