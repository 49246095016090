.category-list-main .page-table-main .table {
  min-width: 600px;
}

/* .category-list-main .advertiserlist-table-main .table .table-th:nth-child(1),
.category-list-main .advertiserlist-table-main .table .table-td:nth-child(1) {
  width: 10%;
}

.category-list-main .advertiserlist-table-main .table .table-th:nth-child(2),
.category-list-main .advertiserlist-table-main .table .table-td:nth-child(2) {
  width: 10%;
}

.category-list-main .advertiserlist-table-main .table .table-th:nth-child(3),
.category-list-main .advertiserlist-table-main .table .table-td:nth-child(3) {
  width: 20%;
} */

.category-images {
  width: 40px;
  height: 40px;
  webkit-mask: url(data:image/svg+xml,%3Csvg viewBox='0 0 124 132' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8282 19.9021C12.4058 23.0387 7.90295 27.5533 4.77312 32.9913C1.64329 38.4292 -0.00301527 44.5985 4.14586e-06 50.8779V81.1128C4.14586e-06 93.8905 6.78925 105.703 17.8282 112.086L43.9203 127.202C49.3397 130.345 55.4888 132 61.7486 132C68.0083 132 74.1575 130.345 79.5768 127.202L105.686 112.086C116.727 105.686 123.514 93.8905 123.514 81.1128V50.8754C123.514 38.1001 116.727 26.2848 105.686 19.9021L79.5792 4.78587C74.1569 1.65044 68.0083 0 61.7498 0C55.4912 0 49.3426 1.65044 43.9203 4.78587L17.8282 19.9021V19.9021Z' fill='%23FDFDFF'/%3E%3C/svg%3E%0A) center / contain no-repeat;
  mask: url(data:image/svg+xml,%3Csvg viewBox='0 0 124 132' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8282 19.9021C12.4058 23.0387 7.90295 27.5533 4.77312 32.9913C1.64329 38.4292 -0.00301527 44.5985 4.14586e-06 50.8779V81.1128C4.14586e-06 93.8905 6.78925 105.703 17.8282 112.086L43.9203 127.202C49.3397 130.345 55.4888 132 61.7486 132C68.0083 132 74.1575 130.345 79.5768 127.202L105.686 112.086C116.727 105.686 123.514 93.8905 123.514 81.1128V50.8754C123.514 38.1001 116.727 26.2848 105.686 19.9021L79.5792 4.78587C74.1569 1.65044 68.0083 0 61.7498 0C55.4912 0 49.3426 1.65044 43.9203 4.78587L17.8282 19.9021V19.9021Z' fill='%23FDFDFF'/%3E%3C/svg%3E%0A) center / contain no-repeat;
  object-fit: cover;
  object-position: center;
}