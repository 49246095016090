.header-date-picker{
    width: 210px;
    margin: 0 20px 0 0;
}
/* .advertiserlist-table-main .table .table-th:nth-child(1), .advertiserlist-table-main .table .table-td:nth-child(1) {
    width: 10%;
}
.advertiserlist-table-main .table .table-th:nth-child(2), .advertiserlist-table-main .table .table-td:nth-child(2) {
    width: 20%;
}
.advertiserlist-table-main .table .table-th:nth-child(3), .advertiserlist-table-main .table .table-td:nth-child(3) {
    width: 15%;
}
.advertiserlist-table-main .table .table-th:nth-child(4), .advertiserlist-table-main .table .table-td:nth-child(4) {
    width: 10%;
} */

.url-link{
    text-decoration: none;
    color: rgb(255, 255, 255);
}