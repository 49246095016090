@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {}

@media screen and (max-width: 991px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {}

@media (max-width: 450px){
    .flex-dash-advertisement{
        flex-direction: column;
    }
    .flex-dash-advertisement .admin-page-title.main-heading{
        margin-bottom: 20px;
    }
    .deposit-btn-main {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 280px) and (max-width:374px) {
    .deposit-btn-main{
        grid-template-columns: 1fr ;
    }
}