/* start admin login css */

.admin-login-main-flex {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: #171423;
}

.admin-login-left-main {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
}

.admin-login-right-main {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
}

.login-left-bg {
  height: 100vh;
  object-fit: cover;
  background-repeat: no-repeat;
  /* background-image: url(../../../assets/images/png/login-bg.jpg); */
  background: #262339;
  background-size: cover;
  background-position: right;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-login-main {
  max-width: 380px;
  margin: auto;
  width: 100%;
}

.login-btn-main .login-btn {
  margin-top: 15px;
}

.checkbox-main .checkbox-lable {
  color: #6b7280;
  margin-left: 0px;
  margin-right: 0px;
}

.checkbox-main {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.checkbox-main .MuiButtonBase-root {
  margin-right: 10px;
  padding: 0px;
}

.checkbox-lable .MuiFormControlLabel-label {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
}

.admin-login-inner .admin-wel-text {
  color: #111827;
  font-size: 22px;
  line-height: 18px;
  /* font-size: 24px;
  line-height: 25px; */
  font-family: "Poppins-Bold";
  margin-bottom: 4px;
}

.admin-login-inner .admin-sign-para {
  /* margin-bottom: 32px; */
  margin-bottom: 11px;
  color: #6b7280;
}

.input-box .form-lable {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 3px;
  /* margin-bottom: 6px; */
  margin-top: 0px;
  color: #fff;
  /* color: #6b7280; */
}

.input-box {
  margin-bottom: 8px;
}

.admin-forgot-para {
  color: #ffffff;
}

.input-box .MuiInputBase-root {
  width: 100%;
  padding-right: 0px;
}

.input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.input-box .form-control-eye input {
  padding: 9px 15px;
  color: #6d8d9d;
  font-family: "Poppins-Regular";
  font-size: 12px;
  line-height: 20px;
  padding: 10px 15px;
  width: 100%;
}

.public-form-wraper .input-box {
  /* margin-bottom: 15px; */
  margin-bottom: 10px;
}

/* end admin login css */

/* start or text design */

.or-text {
  text-align: center;
  color: #6b7280;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  margin: 30px 0px 0px;
  position: relative;
  max-width: max-content;
  background-color: #fff;
}

.condition-content {
  text-align: center;
}

.condition-content .condition-text {
  color: #6b7280;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
}

.condition-content .condition-span {
  color: #114a65;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  text-decoration: none;
}

.or-text-main {
  position: relative;
  margin: 30px 0px;
}

.or-text-main .or-cocntent-text {
  background: #fff;
  margin: auto;
  z-index: 9;
  position: relative;
  text-align: center;
  width: 44px;
  color: #6b7280;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
}

.or-text-main::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #d3d5d6;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* end or text design */

/* start otp page */

.otp-input-box .form-group .form-control input {
  max-width: 70px;
  text-align: center;
  color: #7a779c;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.otp-input-box .form-group .MuiFormControl-root {
  max-width: 70px;
}

.otp-input-box .form-group .form-control input::placeholder {
  color: #7a779c;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.otp-flex-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otp-input-box {
  margin-right: 15px;
  margin-bottom: 10px;
}

.otp-flex-main .otp-input-box:last-child {
  margin-right: 0px;
}

/* end otp page */

/* start chnage password page css */

.change-pass-main {
  /* border: 1px solid #d1d5db; */
  border-radius: 6px;
  /* padding: 20px; */
  max-width: 514px;
  width: 100%;
}

/* end chnage password page css */

/*Common CSS FOR ALL AUth*/

.error-msg {
  text-align: left;
  color: red;
  margin-left: 5px !important;
  font-size: 12px !important;
  margin-top: 5px !important;
}
.error-msg-profile {
  text-align: left;
  color: red;
  margin-left: 5px !important;
  font-size: 12px !important;
  /* margin-top: 5px !important; */
}

.otp-box-main div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.mui-otp {
  /* width: 5em !important; */
  max-width: 100%;
  text-align: center;
  background-color: #161616;
  font-size: 14px;
  line-height: 15px;
  font-family: "Poppins-Regular";
  padding: 9px 15px;
  border: 1px solid #3e3e3e;
  border-radius: 22px;
  color: #fff;
  flex: 1;
}

/* CSS FOR OTP  */

.otp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}

.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 1px solid gray;
  margin-left: 8px;
  outline: none;
  margin-right: 8px;
  background: #f0f3f8ed;
  font-size: 20px;
  color: #6d8d9d;
}

.back-link-box {
  text-align: start;
}

.back-link-box a {
  display: inline-block;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.admin-login-inner .admin-signup {
  margin-top: 20px;
  text-align: center;
}
.scroll-content-auth {
  margin-right: -25px;
  max-height: 465px;
  /* overflow: auto; */
  padding-right: 25px;
}

/* / width / */
.scroll-content-auth::-webkit-scrollbar {
  width: 2px;
}

/* / Track / */
.scroll-content-auth::-webkit-scrollbar-track {
  background: #ececec;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* / Handle / */
.scroll-content-auth::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* / Handle on hover / */
.scroll-content-auth::-webkit-scrollbar-thumb:hover {
  background: #08112f;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.signup-logo {
  width: 340px;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.admin-login-main-flex .form-group .form-control input {
  color: #7a779c;
}

.modal-login .MuiModal-backdrop {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: transparent linear-gradient(to top, #17142359  0%, #17142359  100%);

}

.modal-login .login-modal-logo {
  height: 40px;
  margin-bottom: 20px;
}

.modal-login .input-box .form-control-eye input {
  background: rgb(38 34 56);
  padding: 13px 15px;
  border-radius: 4px 0px 0px 4px;
  -webkit-border-radius: 4px 0px 0px 4px;
  -moz-border-radius: 4px 0px 0px 4px;
  -ms-border-radius: 4px 0px 0px 4px;
  -o-border-radius: 4px 0px 0px 4px;
}

.modal-login .input-box .form-control-eye input:-webkit-autofill {
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #262238;
}

.modal-login .input-box input::placeholder {
  color: #7a779c;
}

.modal-login .form-group .form-control input {
  background-color: rgb(42 38 62 / 80%) !important;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 20px;
  padding: 13px 15px;
}

.modal-login .admin-forgot-para {
  color: #ffffff;
  font-size: 16px;
  line-height: 21px;
}

.modal-login .input-box .form-control-eye:hover fieldset {
  border-color: #153341 !important;
}

/* input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
    -webkit-text-fill-color: #fff;
    border-radius: 0 !important;
    -webkit-box-shadow: inset 0 0 0 1000px #153341;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
} */

.modal-login .input-box .form-control-eye {
  background: rgb(38 34 56);
}

.modal-login .input-box .form-control-eye:hover {
  border: none;
}

.modal-login .delete-modal-inner-main {
  padding: 40px 40px 40px 40px;
}

.modal-login .delete-modal-inner-main .btn-main-primary .btn-primary,
.modal-login .delete-modal-inner-main .btn-main-primary .btn-primary:hover {
  background: linear-gradient(180deg, #f9cc5d 0%, #fe806b 100%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  font-family: "Poppins-Regular";
  height: 40px;
  border-radius: 50px;
  text-transform: capitalize;
  max-width: 100%;
}

.modal-login .form-control-eye svg path {
  fill: #fff;
}

/* .modal-login .input-box .form-control-eye input:-webkit-autofill {
    padding: 10px 15px;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border:1px solid #262339;
  -webkit-text-fill-color: #7a779c;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -moz-transition: background-color 5000s ease-in-out 0s;
  -ms-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
}

.modal-login .delete-modal-inner-main {
  padding: 50px 40px !important;
  max-width: 500px !important;
  background: #171423 !important;
}

.modal-login .admin-login-inner .admin-wel-text {
  color: #ffffff;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Poppins-Medium";
  font-size: 24px;
  line-height: 30px;
}

.modal-login .input-box .form-lable {
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 5px;
  color: #ffffff;
}


.input-box .form-control-eye fieldset {
  border: 0px;
}

.modal-login .resend-otp-code {
  color: #ffffff !important;
}
.public-form-wraper .input-box .form-control-eye{
border: 1px solid #262339;
background: #262339;
}

.modal-auth{
  background-image: url('../../../assets/images/png/Dashboardbackground.png');
}

.div-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.div-flex .category-select-close-icon {
  width: 20px;
  height: 20px;
}

