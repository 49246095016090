@font-face {
    font-family: "Poppins-light";
    src: url(../fonts/Poppins/Poppins-Light.ttf);
  }
  .primary-btn-main .user-btn-primary,
  .primary-btn-main .user-btn-primary:hover{
    background-color: #153341;
    border-radius: 7px;
    width: 100%;
    max-width: 380px;
    color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    font-family: "Poppins-Regular";
    padding: 4.5px 24px;
    text-transform: capitalize;
  }
  .primary-btn-main .user-border-btn,
.primary-btn-main .user-border-btn:hover {
    /* background-color: #153341; */
    border: 1px solid #FFF;
    border-radius: 7px;
    width: 100%;
    max-width: 380px;
    color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    font-family: "Poppins-Regular";
    padding: 4.5px 24px;
    text-transform: capitalize;
}
