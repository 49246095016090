.video-main {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    border-radius: 20px;
    height: calc(100vh - 163px);
}

.video-wrapper{
    display: flex;
}
.video-container{
    width: 100%;
    max-width: 1140px;
    margin: auto;
    padding: 0 15px;
}
.video-container .advertisement-title{
    color: #FFF;
    /* color: #111827; */
    font-size: 40px;
    line-height: 43px;
    font-family: 'Poppins-Regular';
    margin-bottom: 20px;
    text-align: center;
}
.video-main-contain{
    padding: 50px 0;
    background-color: #102934;
}