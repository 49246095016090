@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (max-width: 1025px){
    .copy-text-bg-main{
        min-height: 121px;
    }
}


@media screen and (min-width: 992px) and (max-width: 1279px) {

    .admin-dashboard-inner-box .admin-dash-price{
        font-size: 20px;
        line-height: 35px;
        max-width: 140px;
    }
    /* .dashboard-main .admin-dashboard-inner-box.admin-dash-new-box .admin-dash-right {
        width: 46px;
        height: 46px;
    } */
    .dash-icons {
        height: 36px;
        width: 36px
    }
}


@media screen and (max-width: 991px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .admin-dashboard-inner-box .admin-dash-price {
        max-width: 310px;
        font-size: 20px;
        line-height: 35px;
    }
}

@media (max-width: 767px) {
    .admin-dashboard-inner-box .admin-dash-price {
        max-width: 300px;
        font-size: 20px;
        line-height: 35px;
    }
}

@media screen and (min-width: 280px) and (max-width:374px) {}