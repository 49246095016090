/* start admin edit user page */

.edit-profile-flex {
  /* display: flex;
  align-items: center; */
  margin-bottom: 20px;
  margin-top: 20px;
}
.edit-profile-flex p {
  color: #d32f2f;
}

/* end admin edit user page */

/* start file upload img css */

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  box-shadow: 0px 3px 6px #00000029;
}

.file-upload-btn-main {
  position: relative;
  max-width: max-content;
}

.upload-profile-img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.upload-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.account-setting-button {
  display: flex;
  /* justify-content: space-between; */
}

/* end file upload img css */
.tabs-main-box .MuiTab-root.Mui-selected{
  color: #7a779c;
}
.tabs-main-box .MuiTabs-indicator{
  background-color: #7a779c;
}
.admin-tabs-main .admin-tab{
  font-family: 'Poppins-Regular';
  color: #7a779c;

}
.change-pass-input-box .passwrd-eye{
  position: absolute;
    right: 20px;
}
.change-pass-input-box .passwrd-eye svg{
  width: 20px;
}
.change-pass-input-box .passwrd-eye svg path{
  fill: #7a779c;
}
.change-pass-input-box .form-group .form-control input{
padding-right: 20px;
}
.change-pass-input-box .form-group .form-control .MuiInputAdornment-positionEnd{
  margin-right: 0px;
}
.MuiIconButton-sizeMedium{
  margin-right: 0px !important;
}
.file-upload-btn-main .error-text-custom{
  color: #d32f2f;

}