@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {
    .admin-sidebar-logo {
        height: 35px;
    }
    .admin-sidebar-list-main .admin-sidebar-link {
        font-size: 12px;
        line-height: 15px;
    }
    .admin-sidebar-icons {
        height: 18px;
        height: 18px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {}

/* phone and tablets */
@media screen and (max-width: 991px) {}
@media screen and (min-width: 768px) and (max-width: 991px) {}
@media (max-width: 767px) {
    .admin-header-profile-icon {
        height: 30px;
        width: 30px;
    }
    .admin-sidebar-logo {
        height: 30px;
    }
    .right-main-position-modal {
        right: 12px;
        top: 90px;
    }

    .qr-inner-modal-wallet {
        top: 190px;
        right: 59px;
    }

    .user-qr-modal-content .user-qr-name {
        margin-bottom: 15px;
    }
}