/* start admin dashboard page structure css */

.dashboard-left-main {
  flex: 0 0 250px;
  max-width: 250px;
  width: 100%;
  transition: 0.5s all;
  background-color: #262339;
  height: 100vh;
  left: 0px;
  /* overflow: auto; */
  /* padding: 0px 16px; */
  position: fixed;
  top: 0;
  width: 250px;
  z-index: 999;
  border-right: none;
  transition: 0.5s all;
}

.dashboard-right-main {
  flex: 1;
  position: relative;
  padding-left: 250px;
  background: #262339;
  transition: 0.5s all;
}

.dashboard-containt-main {
  background-color: #ffffff;
  /* height: calc(100vh - 65px); */
  overflow: auto;
  border-top-left-radius: 2.5rem;
  padding: 33px 30px 30px 30px;
}

.dashboard-main {
  /* display: flex; */
  width: 100%;
}

.admin-sidebar-deactive {
  /* flex: 0 0 0px;
      max-width: 0px; */
  width: 85px;
  max-width: 85px;
  left: 0px;
  transition: 0.5s all;
}

/* end admin dashboard page structure css */
/* 17-11-2023 */

.dashboard-main .dashboard-containt-main {
  background: rgb(23 20 35);
  height: 100vh;
  background: transparent linear-gradient(180deg, #171423 0%, #262339 100%) 0% 0%;
}

.dashboard-main .admin-dashboard-inner-box {
  border: 1px solid #ffffff;
}

.dashboard-main .admin-dashboard-inner-box.admin-dash-new-box {
  /* border-right: 1px dashed #262626; */
  border-radius: 0;
  padding: 0;
  /* height: 136px; */
  flex-direction: column;
  background: transparent;
  align-items: start;
  justify-content: center;
}

.dashboard-box-cont :nth-of-type(5) .admin-dashboard-inner-box.admin-dash-new-box {
  border-right: none;
}

.dashboard-main .admin-dashboard-inner-box .admin-dash-text,
.dashboard-main .admin-dashboard-inner-box .admin-dash-price,
.dashboard-main .dashboard-content .admin-page-title,
.dashboard-main .flex-drop-main .admin-header-drop {
  color: #ffffff !important;
}

.dashboard-main .admin-dashboard-inner-box.admin-dash-new-box .admin-dash-text,
.dashboard-main .admin-dashboard-inner-box.admin-dash-new-box .admin-dash-price {
  color: #262626 !important;
  font-weight: 700 !important;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.dashboard-main .admin-dashboard-inner-box.admin-dash-new-box .admin-dash-text {
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

.dashboard-main .dashboard-content .admin-page-title.main-heading,
.admin-page-title .user-list-page-title.main-heading {
  color: #ffffff !important;
  font-weight: 400;
}

/* .dashboard-main  .dash-icons {
    filter: brightness(20);
} */
.dashboard-main .admin-sidebar-main {
  border-right: unset;
}

.dashboard-main .admin-header-main {
  background-color: #262339;
  border-bottom: unset;
}

.dashboard-main .admin-dashboard-inner-box {
  /* border: 1px solid #18485a; */
  border: none;
  background-color: #0e2a36;
}

.dashboard-main .admin-sidebar-list-main .admin-sidebar-link.active {
  color: #ffffff;
  /* background-color: #0d2630; */
}

/* .dashboard-main .bergurmenu-icon {
    filter: brightness(20);
} */
.dashboard-main .meta-mask-btn-sec .meta-mask-btn,
.dashboard-main .meta-mask-btn-sec .meta-mask-btn:hover {
  background: #162b38;
}

.admin-sidebar-left {
  padding-left: 0px;
  transition: 0.5s all;
}

.dashboard-left-main.admin-sidebar-deactive .admin-sidebar-list-main .admin-sidebar-listitem .title-sidebar-content {
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.dashboard-left-main.admin-sidebar-deactive .admin-sidebar-listitem:hover .title-sidebar-content {
  width: max-content;
  opacity: 1;
  background: #262339;
  max-width: max-content;
  padding: 5px;
  border-radius: 6px;
  transform: translateX(-1rem);
  box-shadow: 0 0 0.625rem rgb(63 57 94 / 54%);
  white-space: nowrap;
  margin-left: 15px;
}

.dashboard-main .admin-sidebar-listitem.active .admin-sidebar-link .title-sidebar-content {
  color: #fd8a69;
}

.dashboard-main .admin-sidebar-listitem.active .admin-sidebar-link svg {
  filter: brightness(0) saturate(100%) invert(71%) sepia(86%) saturate(1214%) hue-rotate(311deg) brightness(95%) contrast(122%);
}


.title-admin-drop .admin-header-drop {
  font-size: 14px;
  font-family: "Poppins-Medium";
  color: #fff;
  cursor: pointer;
}

.form-group .css-32yp37-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 8px;
}

.flex-userlisting {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-details-show {
  padding: 10px 20px;
  position: relative;
}

.flex-gap-between {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.down-uparrow-btn .downup-icon {
  height: 14px;
  width: 14px;
  filter: brightness(0) invert(1);
}

.down-uparrow-btn .updown-btn {
  min-width: unset;
}

.flex-user-img {
  display: flex;
  
}

.dashboard-box-cont {
  cursor: pointer;
  /* background: linear-gradient(123.38deg, #cfdaee 18.93%, #f2fafd 44.59%, #f0f5fb 55.55%, #e6e2f0 69.86%); */
  border-radius: 16px;
  padding: 8px 16px;
  /* box-shadow: 0 0 1rem rgba(84, 99, 246, .1); */
  /* border: 1px solid #e4e7ee; */
  margin-bottom: 20px;
  /* background: linear-gradient(to top, #c1dfc4 0%, #deecdd 100%); */
  position: relative;
  overflow: hidden;
  background: transparent linear-gradient(115deg, #f9cc5d 0%, #fe806b 47%, #4cb58c 100%) 0% 0% no-repeat padding-box;
}

.dashboard-box-cont:after {
  content: "";
  position: absolute;
  left: -100px;
  bottom: -90px;
  width: 192px;
  height: 232px;
  background: rgb(255 255 255 / 15%);
  border-radius: 50%;
}

.dashboard-box-cont:before {
  content: "";
  position: absolute;
  right: -128px;
  bottom: -21px;
  width: 206px;
  height: 210px;
  background: rgb(255 255 255 / 15%);
  border-radius: 50%;
}



.dashboard-main .dashboard-content .admin-page-title.sub-text-cont {
  font-size: 16px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.dashboard-main .dashboard-content .admin-page-title.sub-text-cont span.sub-digit {
  color: #7a7798 !important;
  margin-left: 4px;
  margin-right: 6px;
  font-weight: bold;
}

.user-logout-menu-show {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.user-logout-menu-show.open {
  max-height: max-content;
  /* Adjust based on the content height */
  opacity: 1;
}

.user-logout-menu-show .user-list-drop {
  padding: 0px 0px 0px 30px;
}

.user-logout-menu-show .user-list-drop .user-listitem-drop {
  padding: 5px 14px;
}

.user-logout-menu-show .user-list-drop .user-listitem-drop a {
  font-size: 14px;
  text-decoration: none;
  font-family: "Poppins-Medium";
  color: #c7c9fd;

}

.down-uparrow-btn.open {
  transform: rotate(179deg);
}

.dashboard-left-main.admin-sidebar-deactive .up-down-arrow {
  display: none;
}

.dashboard-left-main.admin-sidebar-deactive .sidebar-inner-drop-ul {
  /* display: none; */
  background: #262339;
  box-shadow: 0 0 1rem rgba(84, 99, 246, .1);
  border-radius: 5px;
  position: absolute;
  right: -369%;
  top: 0;
  border: 1px solid #3f395e;
}

.submenu-sidebr-details {
  position: relative;
}

.dashboard-left-main.admin-sidebar-deactive .down-uparrow-btn .downup-icon,
.dashboard-left-main.admin-sidebar-deactive .title-admin-drop .admin-header-drop {
  display: none;
}

.dashboard-left-main.admin-sidebar-deactive .sidebar-inner-drop-ul {
  /* display: none; */
  background: #fff;
  box-shadow: 0 0 1rem rgba(84, 99, 246, .1);
  border-radius: 5px;
  position: absolute;
  right: -172%;
  top: 0;
}

.submenu-sidebr-details {
  position: relative;
}

.dashboard-left-main.admin-sidebar-deactive .down-uparrow-btn .downup-icon,
.dashboard-left-main.admin-sidebar-deactive .title-admin-drop .admin-header-drop {
  display: none;
}

.dashboard-left-main.admin-sidebar-deactive .user-logout-menu-show.open {
  background: #252239;
  box-shadow: 0 0 1rem rgba(84, 99, 246, .1);
  border-radius: 5px;
  position: absolute;
  right: -152%;
  top: 10px;
  border: 1px solid #3f395e;
}

.dashboard-left-main.admin-sidebar-deactive .user-logout-menu-show.open .user-list-drop {
  padding: 5px 0px;
}

.dashboard-left-main.admin-sidebar-deactive .user-logout-menu-show .user-list-drop .user-listitem-drop {
  color: #262626;
  display: block !important;

}

.dashboard-left-main.admin-sidebar-deactive .user-logout-menu-show.open .deacive-user-show {
  font-family: 'Poppins-Medium';
}

.deacive-user-show,
.submenu-title {
  display: none;
}

.dashboard-left-main.admin-sidebar-deactive .submenu-title {
  margin: 0px 16px;
  font-family: 'Poppins-Medium';
  font-size: 14px;
  border-bottom: 1px solid #3f395e;
  padding-bottom: 5px;
  display: block;
  color: #fff;
}

.sidebar-inner-drop-ul {
  padding: 0px 0px 0px 29px !important;
}

.sidebar-inner-drop-ul .sidebar-inner-dropli {
  font-family: 'Poppins-Regular';
  font-size: 14px;
  padding-left: 24px;
  position: relative;
}

.sidebar-inner-drop-ul .sidebar-inner-dropli::after {
  content: '';
  height: 6px;
  width: 6px;
  position: absolute;
  left: 0;
  background: #c7c9fd;
  border-radius: 10px;
}


.sidebar-inner-drop-ul .sidebar-inner-dropli .admin-menu-link {
  text-decoration: none;
  color: #c7c9fd;
}

.dashboard-left-main.admin-sidebar-deactive .flex-gap-between {
  gap: 0px;
}

.dashboard-left-main.admin-sidebar-deactive .user-logout-menu-show .user-list-drop .user-listitem-drop a {
  color: #ffffff;
}

.dashboard-containt-main .dashboard-content .admin-page-title {
  color: #262626 !important;
  font-weight: 600;
}

.change-pass-wrapper .form-group .form-control {
  margin-bottom: 8px;
}

.filterehite {
  width: 20px;
}

.dashboard-main .admin-sidebar-list-main .admin-sidebar-link.active svg {
  filter: brightness(0) saturate(100%) invert(71%) sepia(86%) saturate(1214%) hue-rotate(311deg) brightness(95%) contrast(122%);
}

.dashboard-main .admin-sidebar-list-main .admin-sidebar-link.active,
.dashboard-main .admin-sidebar-listitem.active .admin-sidebar-link {
  color: #fd8a69;
  background-color: #fd8a691a;
}

.dashboard-main .admin-sidebar-list-main .admin-sidebar-listitem.active::after {
  content: "";
  background: #fd8a69;
  height: 100%;
  width: 4px;
  position: absolute;
  left: -10px;
  border-radius: 10px;
}

.sidebar-inner-drop-ul {
  padding: 0px 0px 0px 29px !important;
}

.admin-sidebar-listitem.active+.sidebar-inner-drop-ul .sidebar-inner-dropli.active::after {
  background: #f88563;
}

.admin-sidebar-listitem.active+.sidebar-inner-drop-ul .sidebar-inner-dropli.active .admin-menu-link {
  color: #f88563 !important;
}

.dashboard-main .admin-sidebar-listitem .admin-sidebar-link {
  border-radius: 4px;
}

.up-down-arrow {
  position: absolute;
  right: 7px;
}

.dashboard-left-main.admin-sidebar-deactive .custom-ui-btn .bergurmenu-icon {
  transform: rotate(360deg);
}