@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {
    .dashboard-main .admin-dashboard-inner-box.admin-dash-new-box .admin-dash-text {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 991px) {
    
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .dashboard-box-cont .MuiGrid-container .MuiGrid-item:nth-child(even) .admin-dashboard-inner-box.admin-dash-new-box {
        border-right: 0px;
    }
}

@media (max-width: 767px) {
    .dashboard-main .admin-dashboard-inner-box.admin-dash-new-box{
        border-right: none;
        /* border-bottom:1px dashed #262626;; */
    }
}

@media screen and (min-width: 280px) and (max-width:374px) {}