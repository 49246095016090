/* start admin edit user page */

.edit-profile-flex {
  /* display: flex;
  align-items: center; */
  margin-bottom: 20px;
  margin-top: 20px;
}

/* end admin edit user page */

/* start file upload img css */

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  box-shadow: 0px 3px 6px #00000029;
}

.file-upload-btn-main {
  position: relative;
  max-width: max-content;
}

.upload-profile-img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.upload-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.account-setting-button {
  display: flex;
}

/* end file upload img css */
.tabs-main-box .MuiTab-root.Mui-selected {
  color: #fff;
}

.tabs-main-box .MuiTabs-indicator {
  background-color: #fff;
}

.admin-tabs-main .admin-tab {
  font-family: 'Poppins-Regular';

}

/* .form-group .MuiFormHelperText-root {
      color: #d32f2f;
    } */
.form-group .error-text-custom {
  color: #d32f2f;
}