@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {
  .advertisement-flex-w-100 {
    align-items: start !important;
    justify-content: start;
}

      /* .mb-0-user-list {
            gap: 10px;
            justify-content: end !important;
            align-items: end !important;
            flex-direction: column ;
        } */
    .adver-width{
      justify-content: end;
    }
    .flex-search-details{
      margin-right: 0px !important;
      justify-content: end;
    }
    .search-box-bg{
      position: relative;
    }
}

@media (max-width: 1080px){
  .advertisement-flex-w-100 {
    align-items: start !important;
    justify-content: start;
    flex-direction: column;
}

.advertisement-flex-w-100 .admin-page-title{
  margin-bottom: 20px !important;
}
}

@media screen and (max-width: 991px) {
  .advertisement-flex-w-100{
    width: 100% !important;
  }
      .mb-0-user-list {
            gap: 10px;
            justify-content: start !important;
            align-items: start !important;
            flex-direction: column ;
        }
    .adver-width{
      justify-content: end;
    }
    .flex-search-details{
      margin-right: 0px !important;
      justify-content: end;
    }
    .search-box-bg{
      position: relative;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 767px) {

  .w-100-fill-res{
    width: 100%;
  }

  .filter-flex-content {
    max-width: 100%;
}

      /* .user-list-flex {
            display: flex;
            align-items: self-start;
            flex-direction: column;
        } */
        .flex-advertisement-details,.btn-list-advertisement{
            flex-direction: column;
            align-items: start;
            width: 100%;
        }
        .flex-advertisement-details .admin-page-title {
            margin-bottom: 0px !important;
        }
        .btn-list-advertisement, .btn-list-advertisement .flex-search-details{
            width: 100%;
        }
        .flex-search-details{
            margin-bottom: 0px !important;
        }
}


@media (max-width: 705px){
  .flex-advertisement-details .flex-search-details {
    flex-direction: column;
}

.flex-dash-advertisement .flex-advertisement-details + 
 .user-search-box.flex-search-details {
    flex-direction: column;
    width: 100%;
}

.adver-width .user-search-box.flex-search-details {
    flex-direction: column;
}

.adver-width {
    width: 100%;
}

.adver-width .deposit-flex-btn {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.deposit-flex-btn .adduser-btn-main {
    width: unset !important;
}

.adver-width .deposit-flex-btn .flex-search-details {
    display: unset;
}
}

@media (max-width: 460px){
  .adver-width .deposit-flex-btn{
    grid-template-columns: 1fr;
  }
  .deposit-flex-btn{
    flex-direction: column;
  }

}
@media screen and (min-width: 280px) and (max-width:374px) {}